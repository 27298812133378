import React, { useState, useRef, useEffect, ReactNode, ReactElement } from 'react';
import './DraggablePanel.css';

interface DraggablePanelProps {
  children: ReactNode;
  headerElement?: ReactElement;
  className?: string;
  isHeaderHovered?: boolean;
}

const DraggablePanel: React.FC<DraggablePanelProps> = ({ children, headerElement, className, isHeaderHovered }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDraggingState, setIsDraggingState] = useState(false);
  const isDragging = useRef(false);
  const panelRef = useRef<HTMLDivElement>(null);
  const offset = useRef({ x: 0, y: 0 });
  const SCROLLBAR_WIDTH = 25;
  const MARGIN_FROM_RIGHT = 15;
  const MARGIN_FROM_TOP = 48;

  useEffect(() => {
    if (panelRef.current) {
      const { offsetWidth, offsetHeight } = panelRef.current;
      setPosition({
        x: window.innerWidth - offsetWidth - MARGIN_FROM_RIGHT, 
        y: MARGIN_FROM_TOP, 
      });
    }
  }, []);

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    isDragging.current = true;
    setIsDraggingState(true);
    offset.current = {
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging.current) return;

    const newX = event.clientX - offset.current.x;
    const newY = event.clientY - offset.current.y;

    const boundedX = Math.max(0, Math.min(window.innerWidth - SCROLLBAR_WIDTH - (panelRef.current?.offsetWidth || 0), newX));
    const boundedY = Math.max(0, Math.min(window.innerHeight - (panelRef.current?.offsetHeight || 0), newY));

    setPosition({ x: boundedX, y: boundedY });
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    setIsDraggingState(false);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div
      ref={panelRef}
      className={`draggable-panel ${className} ${isDraggingState ? 'dragging' : ''} ${isHeaderHovered ? 'hovering' : ''}`}
      style={{
        left: position.x,
        top: position.y,
        position: 'absolute',
      }}
    >
      {headerElement && React.cloneElement(headerElement, { onMouseDown: handleMouseDown })}
      <div>{children}</div>
    </div>
  );
};

export default DraggablePanel;