import React,{ useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Context
import { UserContext } from './common/contexts/UserContext';

// Pages
import LoginPage from "./pages/LoginPage"
import MainPage from "./pages/MainPage"
import NotFoundPage from "./pages/NotFoundPage"
import UnavailablePage from "./pages/UnavailablePage"
import ErrorPage from "./pages/ErrorPage"
import { DataContextProvider } from './common/contexts/DataContext';
import { ApolloProvider } from '@apollo/client';
import { EventContextProvider } from './common/contexts/EventContext';
import { DialogContextProvider } from './common/contexts/DialogContext';
import apolloClient from './common/utils/createApolloClient';
import { resolveEndpoints } from './common/utils/resolveEndpoints';
import SelectTwinPage from './pages/SelectTwinPage';

interface PrivateRouteProps {
    children: JSX.Element
}
  
const ProtectedRoute: React.FC<PrivateRouteProps> = ({ children }) => {

    const { authCreds } = useContext(UserContext);

    if (!authCreds) {
        return <Navigate to="/login" />;
    }

    const endpoints = resolveEndpoints(authCreds.graphqlUrl)

    return  <ApolloProvider client={apolloClient(endpoints.httpUrl, endpoints.wsUrl, authCreds.tokens.graphqlToken)}>
                <DataContextProvider>
                    <EventContextProvider>
                        <DialogContextProvider>
                            {children}
                        </DialogContextProvider>
                    </EventContextProvider>
                </DataContextProvider>
            </ApolloProvider>
}

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/select-twin" element={
                    <ProtectedRoute>
                         <SelectTwinPage/>
                    </ProtectedRoute>
                }
                />
                <Route path="/" element={
                    <ProtectedRoute>
                         <MainPage/>
                    </ProtectedRoute>
                }
                />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="/unavailable" element={<UnavailablePage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Router>
    )
}

export default AppRouter