/*
 * prependZero
 * Prepends a zero to a value and returns string
 * 
*/
export const prependZero = (value: number, threshold: number = 10) => {
    if (value < threshold) {
        return `0${value}`
    }
    return value.toString()
}