import Config from "../../../common/Config"
import { useUserContext } from "../../../common/contexts/UserContext"


export const useSecureModelURL = (modelFileName: string, useCacheBust = true) => {

    const { authCreds } = useUserContext()

    let url = Config.modelBaseURL + modelFileName;

    if (authCreds) {
         // N.B. the responsibiliy is on the developer to make sure that auth is going to work is modelBaseUrl needs a token
        url += authCreds.tokens.modelUrlKey
    }

    if (useCacheBust) {
        const date = new Date()  // now
        const cacheBust = `&xyz=${date.getDay()}${date.getHours()}${date.getMinutes()}`  // N.B. this is a workaround for useLoader not working with millisecond cache bust
        url += cacheBust
    }
   
    return url

}