import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Fluent Provider
import { FluentProvider, teamsLightTheme, PartialTheme } from '@fluentui/react-components';

// Theme
import { lightTheme } from './common/fluent2/lightTheme';

// Context
import { AppContextProvider } from './common/contexts/AppContext'
import { FilterContextProvider } from './common/contexts/FilterContext';
import { SettingsContextProvider } from './common/contexts/SettingsContext';
import { TwinContextProvider } from './common/contexts/TwinContext';
import { UserContextProvider } from './common/contexts/UserContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme: PartialTheme = {
  ...teamsLightTheme,
  ...lightTheme
}


root.render(
  <React.StrictMode>
    <FluentProvider theme={theme}>
      <AppContextProvider>
        <UserContextProvider>
          <SettingsContextProvider>
            <TwinContextProvider>
              <FilterContextProvider>
                <App/>
              </FilterContextProvider>
            </TwinContextProvider>
          </SettingsContextProvider>
        </UserContextProvider>
      </AppContextProvider>
    </FluentProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
