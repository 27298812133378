import { useState } from "react";
import {
	BufferGeometry,
} from "three";
import { Label3D } from "../../Label3D";
import { Hitbox } from "../../Hitbox";
import { useFilterContext } from "../../../../common/contexts/FilterContext";
import { useEventContext } from "../../../../common/contexts/EventContext";
import { SelectionOutlines } from "../../outlines/SelectionOutline";
import { TwinEntityType } from "../../../../@types/TwinEntityType";
import { LabelMode } from "../../../../@types/LabelMode";
import { TwinEntity } from "../../../../generated/devGraphql";
import { stringToTwinEntityType } from "../../../../common/utils/stringToTwinEntityType";
import { determineVisibility, getMenuHovered, getSelectionCriteria } from "../../utils/filterUtils";
import { NotTracked } from "../../behaviours/NotTracked";
import { Capacity } from "../../behaviours/Capacity";

interface Props {
	entity: TwinEntity; // The current area being passed into the component.
    mapDiv: HTMLDivElement; //Reference to the Mapbox map container element from React-Three-Map for event triggering css changes to the map cursor style.
	geometry: BufferGeometry; // N.B. the geometry's bounding box should have been computed already
	hitboxGeometry: BufferGeometry
	depth: number // the depth we're at in the twin heirachy
	lineage: TwinEntity[] // the acenstry of this entity, oldest first
}

/**
	* Area based on a structure asset
    * 
*/

export const AreaFromStructure = ({ entity, mapDiv, geometry, hitboxGeometry, depth, lineage }: Props) => {

	const { filter } = useFilterContext();
	const { currentHoveredEntity } = useEventContext();
	const [hover, setHover] = useState(false)
	const [pressed, setPressed] = useState(false)

	const parent = lineage[lineage.length - 1]
    const parentType = stringToTwinEntityType(parent.type.name)
    const isTopLevelArea = parentType === TwinEntityType.STRUCTURE
	const { filtersExist, 
		areaIsSelected, 
		parentIsSelected,
		siblingIsSelected 
	} = getSelectionCriteria(entity, parent, filter)
	

	const fullLineageIncludingThisEntity = [...lineage, entity]
	const menuHovered = getMenuHovered(fullLineageIncludingThisEntity, currentHoveredEntity)

	const showSolidOutline = areaIsSelected  || pressed;
	const showDashedOutline = (hover || menuHovered) && !areaIsSelected
	const showBehaviourBoxOutline = !(showSolidOutline || showDashedOutline)

	const behavioursVisible = determineVisibility(entity, { 
		filtersExist, 
		areaIsSelected, 
		parentIsSelected, 
		siblingIsSelected, 
		isTopLevelArea })
	const colorMode = filtersExist && !areaIsSelected && siblingIsSelected ? 'greys' : 'colors'
	
	return (
			<group position={[ 0, (depth / 4000), 0]}> 
				<Hitbox 
					entity={entity}
					geometry={ hitboxGeometry } 
					setHover={setHover} 
					setPressed={setPressed}
					mapDiv={mapDiv} 
					lineage={lineage}
				/>
				<SelectionOutlines geometry={hitboxGeometry} showDashedOutline={showDashedOutline} showSolidOutline={showSolidOutline}/>
				{ entity.tracked ? 
				<Capacity 
					entity={entity} 
					geometry={hitboxGeometry} 
					colorMode={colorMode}
					visible={behavioursVisible}
					showOutline={showBehaviourBoxOutline}
				/> 
				: 
				<NotTracked 
					geometry={hitboxGeometry} 
					visible={behavioursVisible} 
					showOutline={showBehaviourBoxOutline}
				/>
				}
				{(hover || menuHovered || areaIsSelected) &&
					<Label3D 
					entity={entity}
					entityType={TwinEntityType.FLOOR} // this is movico specific
					labelMode={areaIsSelected ? LabelMode.FULL : LabelMode.BRIEF} 
					boundingBox={geometry.boundingBox!} // N.B. the geometry's bounding box should have been computed already
					 
				/>
				}
			</group>
	);
}


