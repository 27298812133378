import { useEffect } from 'react';
import AppRouter from './AppRouter'
import Hotjar from '@hotjar/browser';
import Config from './common/Config';
import './App.css';

function App() {

  const hotjarVersion = 6;

  useEffect(() => {
    if (Config.hotJarSiteId && window.location.hostname === 'app.twinlabs.ai') {
      Hotjar.init(parseInt(Config.hotJarSiteId), hotjarVersion);
    }
  }, [])

  return (
    <AppRouter />
  );
}

export default App;
