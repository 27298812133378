/*
 * getWindSpeed
 * Returns Wind Speed Hero Metric for a specific entity or business ID
 *
*/
// Types
import { DataMode } from "../../../@types/DataMode"
import { DataValue } from "../../../@types/Data/DataValue"

// Utils
import { getDataValue } from "./getDataValue"

export const getWindSpeed = (dataMode: DataMode, data: DataValue[], id: string): string => {
    if (data && data.length > 0) {
        const windSpeed = getDataValue(dataMode, data, id)?.value
        return isNaN(windSpeed) ? `N/A` : `${windSpeed}m/s`
     } else {
        return 'N/A'
     }
}