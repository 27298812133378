import { ExtrudeGeometry, Shape } from "three";
import  { expandOutline } from "./outlineUtils";
import { extrudeSettingsArea } from "./extrusionConfig";
import { getPolygonCentroid } from "./geometry";
import { Polygon } from "../../../generated/graphql";
import { coordLocalOutlineToVector2 } from "./coorUtils";



export function getAreaOutlineShape(polygons: Polygon[], depth: number) {

    const shapes: Shape[] = []
    let centre = {latitude: 0, longitude: 0} // this definitely gets set later, just that tyescript doesn't realise

    polygons.forEach((polygon, idx) => {

        // take the last polygon centroid, as per https://github.com/TwinLabs-ai/graph/blob/68513dfb36f3690830ce407a1340ff673ba359d0/schema/schema.graphql#L88
        if (idx === (polygons.length -1 )) {
            centre = getPolygonCentroid(polygon.coordinates[0].points)
        } 
        
        const worldSpaceOutline = coordLocalOutlineToVector2(polygon.coordinates[0].points)
        const areaPoints = expandOutline(
            worldSpaceOutline,
            -0.06 - depth / 2000 // what are these magic numbers?
        );

        const areaShape = new Shape(areaPoints);

        shapes.push(areaShape)
    }); 

    
    const areaBlock = new ExtrudeGeometry(
        shapes,
        {...extrudeSettingsArea, ...{depth: 3 - depth / 5}} // TODO this will fail once depth gets bigger
    );

    areaBlock.computeBoundingBox(); // required for label
    areaBlock.rotateX(-Math.PI / 2);

    return { centre, areaBlock }

} 
