import { useEffect, useMemo } from 'react'
import { BlendFunction, Effect } from "postprocessing";

// https://github.com/pmndrs/postprocessing/wiki/Custom-Effects
const screenBlendFrag = `
    void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
        outputColor = vec4(0.0, 0.0, 0.0, inputColor.a );
    }
`

class ScreenBlendEffect extends Effect {

	constructor() {

		super("ScreenBlendEffect", screenBlendFrag);

		this.blendMode.blendFunction = 
    BlendFunction.SCREEN
	}

}

export const ScreenBlend = () => {

  const effect = useMemo(() => new ScreenBlendEffect(), [])

  useEffect(() => {
    return () => {
      effect.dispose()
    }
  }, [effect])

  return <primitive object={effect} />
}