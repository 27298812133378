import React from "react"
import { Spinner } from "@fluentui/react-components";
import type { SpinnerProps } from "@fluentui/react-components";

interface Props {
    className?: string
    props?: SpinnerProps
    custom?: JSX.Element
}

const TLSpinner:React.FC<Props> = ({className, props, custom}) => {
    return (
        <div className={className}>
            {custom ? custom : <Spinner {...props} />}
        </div>
    )
}

export default TLSpinner