import { gql } from '@apollo/client';

export const GET_ACCOUNT = gql`
    query GetAccountByEmail($email: String) {
        getAccount(email: $email) {
            id
            firstName
            lastName
            phone
            email
            preferences
            role {
                name
            }
            organisation {
                id
                name
                entities {
                    name
                    bID
                }
            }
        }
    }
`