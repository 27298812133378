import React, { useEffect, useState } from "react";

// Types
import { LogEntry } from "../@types/LogEntry";
import { LogLevel } from "../@types/LogLevel";
import { TabOption } from "../@types/Tab";

// Components
import TLSwitcher from "./elements/TLSwitcher";

interface Props {
    data: LogEntry[]
}

const levelTabOptions: TabOption[] = [
    {
        text: 'All',
        value: '',
    },
    {
        text: 'Fatal',
        value: LogLevel.FATAL,
    },
    {
        text: 'Critical',
        value: LogLevel.CRITICAL,
    },
    {
        text: 'Error',
        value: LogLevel.ERROR,
    },
    {
        text: 'Warning',
        value: LogLevel.WARNING,
    },
    {
        text: 'Info',
        value: LogLevel.INFO,
    },
    {
        text: 'Debug',
        value: LogLevel.DEBUG,
    },
]

const getBackgroundColor = (level: LogLevel) => {
    let bgColor
    switch (level) {
        case LogLevel.DEBUG:
            bgColor = 'bg-grey-200'
            break;
        case LogLevel.INFO:
            bgColor = 'bg-blue-200'
            break;
        case LogLevel.WARNING:
            bgColor = 'bg-orange-200'
            break;
        case LogLevel.ERROR:
            bgColor = 'bg-red-200'
            break;
        case LogLevel.CRITICAL:
            bgColor = 'bg-red-300'
            break;
        case LogLevel.FATAL:
            bgColor = 'bg-black'
            break;
        default:
            bgColor = 'bg-grey-200'
            break;
    }
    return bgColor
}

const getTextColor = (level: LogLevel) => {
    let textColor
    switch (level) {
        case LogLevel.DEBUG:
            textColor = 'text-grey-600'
            break;
        case LogLevel.INFO:
            textColor = 'text-blue-700'
            break;
        case LogLevel.WARNING:
            textColor = 'text-orange-600'
            break;
        case LogLevel.ERROR:
            textColor = 'text-red-600'
            break;
        case LogLevel.CRITICAL:
            textColor = 'text-red-700'
            break;
        case LogLevel.FATAL:
            textColor = 'text-white'
            break;
        default:
            textColor = 'text-grey-600'
            break;
    }
    return textColor
}

const sortData = (data: LogEntry[]) => {

    const logLevelOrder = levelTabOptions.slice(1).map(option => option.value);

    const sortedData = data.sort((a, b) => {
        const levelComparison = logLevelOrder.indexOf(a.level) - logLevelOrder.indexOf(b.level);

        if (levelComparison !== 0) {
            return levelComparison;
        }

        return a.message.localeCompare(b.message);
    });

    return sortedData;
}

const PreFlightReport: React.FC<Props> = ({ data }) => {

    const [currentLogLevel, setCurrentLogLevel] = useState<LogLevel | null>(null)
    const [eventLog, setEventLog] = useState<LogEntry[]>(sortData(data))

    const handleLogLevelSwitcher = (value: LogLevel) => {
        setCurrentLogLevel(value)
    }

    useEffect(() => {
        if (currentLogLevel) {
            const filteredLog = data.filter(obj => obj.level === currentLogLevel);
            setEventLog(filteredLog)
        } else {
            setEventLog(data)
        }
    }, [
        currentLogLevel,
        data,
        eventLog
    ])


    return (
        <>
            <div className="mb-5 flex items-center justify-center">
                <TLSwitcher
                    options={levelTabOptions}
                    setValue={handleLogLevelSwitcher}
                />
            </div>
            {eventLog && eventLog.length > 0 ?
                <>
                    {eventLog.map((entry, i) => {
                        return (
                            <div key={i} className={`mb-1  flex items-center justify-center ${i % 2 === 0 ? 'bg-slate-100' : ``}`}>
                                <div className="w-1/12 mr-1">
                                    <p style={{ fontSize: '0.5rem' }} className={`text-center font-semibold p-1 ${getTextColor(entry.level)} ${getBackgroundColor(entry.level)}`}>{entry.level}</p>
                                </div>
                                <div className="w-1/12 mr-1 flex">
                                    <p style={{ fontSize: '0.5rem' }}>{entry.code}</p>
                                </div>
                                <div className="w-8/12">
                                    <p className="text-xs">{entry.message}</p>
                                </div>
                                <div className="ml-auto">
                                    {entry.href ? <a className="text-xs text-blue-800 pr-1" href={entry.href} target="_blank" rel="noreferrer">View Doc</a> : null}
                                </div>
                            </div>
                        );
                    })}
                </>
                :
                <p className="p-2 my-5 bg-blue-200 text-blue-700 text-center uppercase text-xs">No entries found</p>
            }
        </>
    )
}

export default PreFlightReport