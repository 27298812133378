/*
 * instantQuery
 * Time series range query
 * 
*/
import { gql } from '@apollo/client';

export const INSTANT_QUERY = gql`
    query instantQuery(
        $query: String!,
        $time: String!,
        ) {
        instantQuery(
            query: $query,
            time: $time,
        ) {
            value
            executionTimeMs
        }
    }
`