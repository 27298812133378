/*
 * getWeekRange
 * Takes in a Date object and returns an object that has properties startDate and endDate. Both can be set to a Date object.
 * 
*/
import { DateTime } from "luxon";
import { WeekRange } from "../../@types/WeekRange";

export const getWeekRange = (dateTimeISO: string): WeekRange => {
    const startOfWeek = DateTime.fromISO(dateTimeISO).startOf('week')
    const endOfWeek = DateTime.fromISO(dateTimeISO).endOf('week')
    if (startOfWeek.isValid && endOfWeek.isValid) {
      const startOfWeekMidnight = startOfWeek.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const endOfWeekElevenFiftyNine = endOfWeek.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
      return { startDate: startOfWeekMidnight.toISO(({ includeOffset: true })), endDate: endOfWeekElevenFiftyNine.toISO({ includeOffset: true }) }
    } else {
      throw new Error("getWeekRange has encountered an invalid date");
    }
}