/*
 * avgTransactionRate (DTE = Digital Twin Entity)
 * This is a wrapper for the timeSeriesRangeQuery function
 * Returns Avg Transaction Rate Hero Metric filtered by Organisation and DTE
 * 
*/

// Utils
import { timeSeriesInstantQuery } from "./timeSeriesInstantQuery";

export const avgTransactionRate = async (
    endDateTime: string,
    step: string = "15m",
    organisation?: string) => {
    
    let filterBy = `metricType='transactionRate'`

    if (organisation) {
        filterBy = filterBy + `,organisation='${organisation}'`
    }

    const query = `avg by(digitalTwinEntity, digitalTwinEntityPath, metric) (avg_over_time(metricvalue{${filterBy}}[${step}]))`
    const result = await timeSeriesInstantQuery(query, endDateTime)

    return result
}