/*
 * getWindOrientation
 * Returns Wind Orientation Hero Metric for a specific entity or business ID
 *
*/
// Types
import { DataMode } from "../../../@types/DataMode"
import { DataValue } from "../../../@types/Data/DataValue"

// Utils
import { getDataValue } from "./getDataValue"

export const getWindOrientation = (dataMode: DataMode, data: DataValue[], id: string): string => {
    if (data && data.length > 0) {
        const windOrientation = getDataValue(dataMode, data, id)?.value
        return isNaN(windOrientation) ? `N/A` : `${windOrientation}`
     } else {
        return 'N/A'
     }
}