/*
 * getIndicator
 * This function calculates a percentage based on the count and capacity args
 * It will utilise evaluateStringAsFunction to resolve an indicator colour if the the Site has a indicator thresholds and colors configured 
 * 
*/

// Types
import { IndicatorColor } from "../../@types/Settings/IndicatorColor";
import { RangeMap } from "../../@types/Settings/RangeMap";

// Utils
import { getIndicatorColor } from "./getIndicatorColor";
import { roundToOneDecimalPlace } from "./roundToOneDecimalPlace";

export const getIndicator = (count: number, capacity: number, config?: RangeMap[] | undefined, selected?: boolean) => {
    const pc = count > 0 && capacity > 0 ? (count / capacity) * 100 : 0
    let color: IndicatorColor = getIndicatorColor(pc, config, selected)
    return {
        pc: roundToOneDecimalPlace(pc),
        color: color,
    }
}