/*
 * maxHeadcountByDTE (DTE = Digital Twin Entity)
 * This is a wrapper for the timeSeriesRangeQuery function
 * Returns Max Headcount Hero Metric filtered by Organisation and DTE
 * 
*/

// Utils
import { timeSeriesRangeQuery } from "./timeSeriesRangeQuery";

export const maxHeadcountByDTE = async (
    startDateTime: string,
    endDateTime: string,
    step: string = "15m",
    organisation: string,
    digitalTwinEntity?: string) => {
    
    let filterBy = `metric='countEntity'`

    if (organisation) {
        filterBy = filterBy + `,organisation='${organisation}'`
    }

    if (digitalTwinEntity) {
        filterBy = filterBy + `,digitalTwinEntity='${digitalTwinEntity}'`
    }

    const query = `max by(digitalTwinEntity,digitalTwinEntityPath, metric) (max_over_time(metricvalue{${filterBy}}[${step}]))`
    return await timeSeriesRangeQuery(query, startDateTime, endDateTime, step)
}