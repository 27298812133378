import { BufferGeometry } from "three";

import {  FROSTED_CRYSTAL_GLOW } from "../theme";
import { OutlineSolid } from "./OutlineSolid";
import { OutlineDashed } from "./OutlineDashed";

interface Props {
	geometry: BufferGeometry;
    showSolidOutline: boolean;
    showDashedOutline: boolean
}

export const SelectionOutlines = ({ geometry, showDashedOutline, showSolidOutline  }: Props) => {
    return  <>
                <OutlineSolid color={ FROSTED_CRYSTAL_GLOW } lineWidth={0.07} geometry={geometry} visible={showSolidOutline}/>
                <OutlineDashed geometry={geometry} visible={showDashedOutline}/>
            </>
}





