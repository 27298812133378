import React from "react"
import './TLFilterClearPill.css';

interface Props {
    label: string
    onClick: () => void
}

const TLFilterClearPill: React.FC<Props> = ({label, onClick}) => {
    return (
        <span className="filter-clear-pill" onClick={onClick}>
            <p className="mr-2">{label}</p>
        </span>
    )
}

export default TLFilterClearPill