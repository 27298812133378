/*
 * maxMetricData
 * Performs queries and returns max data for all Metrics
 * 
*/
// Types
import { TSMetricData } from "../../../@types/Data/TSMetricData";

// Data
import { maxHeadcountByDTE } from "./maxHeadcountByDTE";
import { maxWindOrientation } from "./maxWindOrientation";
import { maxWindSpeed } from "./maxWindSpeed";

export const maxMetricData = async (
    startDateTime: string,
    endDateTime: string,
    step: string = "15m",
    organisation: string
): Promise<TSMetricData> => {
        
        let headcount
        let windOrientation
        let windSpeed

        try {
            headcount = await maxHeadcountByDTE(startDateTime, endDateTime, step, organisation)
        } catch (error) {
            console.log(`Error fetching maxHeadcountByDTE time series data`)
        }

        try {
             windOrientation = await maxWindOrientation(startDateTime, endDateTime, step, organisation)
        } catch (error) {
            console.log(`Error fetching maxWindOrientation time series data`)
        }

        try {
            windSpeed = await maxWindSpeed(startDateTime, endDateTime, step, organisation)
       } catch (error) {
           console.log(`Error fetching maxWindOrientation time series data`)
       }

        return {
            headcount: headcount,
            windOrientation: windOrientation,
            windSpeed: windSpeed,
        }
}