import React, {
    useMemo,
} from "react"
import { DebouncedState } from "use-debounce";

// Types
import { CurrentSelection } from "../../@types/CurrentSelection";
import { DataMode } from "../../@types/DataMode";
import { Entity } from "../../@types/Entity";
import { Entity as EntityPref } from "../../@types/Settings/Entity";
import { LabelDisplayOn } from "../../@types/Settings/LabelDisplayOn";
import { LabelMode } from "../../@types/LabelMode";
import { LabelSet } from "../../@types/Settings/LabelSet";
import { UIComponentHeader } from "../../@types/Settings/UIComponentHeader";

// Context
import { useFilterContext } from "../../common/contexts/FilterContext";
import { useSettingsContext } from "../../common/contexts/SettingsContext";

// Data
import { excludedTypes } from "../../common/data/excludedTypes";

// Utils
import { assetIconComponents } from "../../common/utils/assetIconComponents";
import { findObjectById } from "../../common/utils/findObjectById";
import { findObjectByPropertyValue } from "../../common/utils/findObjectByPropertyValue";
import { getLabels } from "../../common/utils/getLabels";
import { stringToTwinEntityType } from "../../common/utils/stringToTwinEntityType";

// Components
import {
    AccordionHeader,
    AccordionHeaderSize,
    AccordionItem,
    AccordionPanel,
    AccordionToggleData,
} from "@fluentui/react-components";
import AssetAccordion from "./AssetAccordion";
import AssetIndicator from "../AssetIndicator";
import DynamicChartRenderer from "../charts/DynamicChartRenderer";
import LabelHeader from "../LabelHeader";
import Labels from "./Labels";
import TLCard from "../elements/TLCard/TLCard";

interface Props {
    handleMouseEnter: DebouncedState<(id: string, type: "ASSET" | "ENTITY") => void>
    handleMouseLeave: DebouncedState<() => void>
    handleToggle: (isCollapsed: AccordionToggleData<string>) => void
    size: AccordionHeaderSize
    entity: Entity
    previousHoveredEntity?: CurrentSelection | null
    currentHoveredEntity?: CurrentSelection | null
    labelComp: JSX.Element
    live: boolean,
    firstHeaderRendered: boolean
    setFirstHeaderRendered: () => void
}

const AssetAccordionItem: React.FC<Props> = React.memo(({
    size,
    handleToggle,
    handleMouseEnter,
    handleMouseLeave,
    entity,
    currentHoveredEntity,
    live,
    firstHeaderRendered,
    setFirstHeaderRendered,
}) => {

    const { filter } = useFilterContext()
    const { settings } = useSettingsContext()

    const isHovered = useMemo(() => currentHoveredEntity?.id === entity.id, [currentHoveredEntity, entity.id]);
    const enableCurrentSelection = true
    const id = entity.id
    const title = entity.name

    let entityPref: EntityPref | null = null
    let assetHeader: UIComponentHeader | undefined
    let showOverCapacityWarning = false

    // Find associated Entity Preferences/Settings related to the Entity ID
    entityPref = useMemo(() => findObjectById(settings?.entities?.children ? settings?.entities?.children : [], id) as EntityPref | null,
    [
        settings?.entities?.children,
        id,
    ])

    assetHeader = entityPref?.header
    showOverCapacityWarning = assetHeader?.showOverCapacityWarning ? assetHeader?.showOverCapacityWarning : false

    // Chart
    let chartConfig: { xAxisLabels?: { label: string, value: number }[] } | undefined = useMemo(() => entityPref?.content?.chart, [entityPref?.content])
    
    let chart = {
        type: settings?.organisation === 'Howler' ? 'HowlerHorizontalBarChart' : 'HorizontalBarChart',
        config: chartConfig,
    }

    // LabelSet (MEMO)
    let labelSets: LabelSet[] | undefined = useMemo(() => entityPref?.content?.labelSets, [entityPref?.content])

    // Retrieve the labels required
    const labels = useMemo(() => {
        if (labelSets && labelSets.length > 0) {
            return getLabels(
                labelSets,
                stringToTwinEntityType(entity.type.name),
                settings?.labels ? settings?.labels : [],
                LabelMode.FULL,
                LabelDisplayOn.ACCORDION,
                live ? DataMode.LIVE : DataMode.TIME_SERIES)
        } else {
            return []
        }
    }
    ,[
        settings?.labels,
        live,
        entity.type.name,
        labelSets
    ])

    const entityChildren = entity.children?.filter(e => !excludedTypes.includes(e.type.name))
    const hasChartContent = entityChildren && entityChildren.length <= 1 && chart;
    const isSelected = findObjectByPropertyValue(filter, "id", entity.id) ? true : false;
    const shouldShowHover = () => !(hasChartContent && isSelected);

    return (
        <>
            <AccordionItem
                key={entity.id}
                id={`entity-${entity.type.name.toLowerCase()}-${entity.id}`}
                onMouseEnter={() => {
                    if (enableCurrentSelection) {
                        handleMouseEnter(entity.id, 'ENTITY')
                    }
                }}
                onMouseLeave={() => {
                    if (enableCurrentSelection) {
                        handleMouseLeave()
                    }
                }}
                value={entity.id}
            >
                <TLCard className="accordion-item" padding="8px" hovered={shouldShowHover() && isHovered} selected={isSelected}>
                    <>
                        {assetHeader?.showHeader === false ? null :
                            <AccordionHeader
                                icon={entityPref && entityPref.icon ? React.createElement(assetIconComponents[entityPref.icon]) : undefined}
                                size={size ? size : "small"}
                                expandIconPosition="end"
                            >
                                <div className="w-full">
                                    <LabelHeader
                                        id={entity.id}
                                        fontSize={firstHeaderRendered ? '14px' : '12px'}
                                        indicator={
                                            <AssetIndicator 
                                                id={live ? entity.id : entity.bID}
                                                capacity={entity.capacity ? entity.capacity : 0}
                                                showOverCapacityWarning={showOverCapacityWarning}
                                            />
                                        }
                                        title={`${title}`}
                                        showId={assetHeader?.showId ? assetHeader?.showId : false}
                                    />
                                </div>
                            </AccordionHeader>
                        }
                        <AccordionPanel
                        style={{margin: 0}}>

                            {/* Nested Entities */}
                            {entityChildren && entityChildren.length > 0 && (
                                <AssetAccordion
                                    entities={entityChildren}
                                    handleToggle={handleToggle}
                                    live={live}
                                />
                            )}

                            {/* Chart */}
                            {hasChartContent &&
                                <>
                                    <DynamicChartRenderer
                                        componentName={chart.type}
                                        entity={entity}
                                        config={chartConfig}
                                        enableChartHoverSelection={true}
                                        handleMouseEnter={handleMouseEnter}
                                        handleMouseLeave={handleMouseLeave}
                                    />
                                </>
                            }

                            {/* Labels (Optionally displayed and configured within Twin Prefs/Settings) */}
                            {labels && labels.length > 0 && (
                                <Labels entity={entity} labels={labels} />
                            )}

                        </AccordionPanel>
                    </>
                </TLCard>
            </AccordionItem>
        </>
    )
}, (prevProps, nextProps) => {
     // Only re-render if the id matches either current or previous selected values
     // Check if the hover state has changed specifically for the current item (not nested)
    const hasCurrentHoverChanged = 
    (prevProps.currentHoveredEntity?.id === prevProps.entity.id) !== 
    (nextProps.currentHoveredEntity?.id === nextProps.entity.id);

    // Ensure re-render if the live mode status changes
    const hasLiveModeChanged = nextProps.live !== prevProps.live;

    // Avoid re-renders on nested panel hover by focusing only on direct changes to the Accordion item
    return !(hasCurrentHoverChanged || hasLiveModeChanged);
})

export default AssetAccordionItem