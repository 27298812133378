/* 
* Imports a Apache EChart component dynamically based on the chart type set in the data
*
*/
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { DebouncedState } from 'use-debounce';

// Context
import { useDataContext } from '../../common/contexts/DataContext'
import { useFilterContext } from '../../common/contexts/FilterContext'
import { useSettingsContext } from '../../common/contexts/SettingsContext'
import { useTwinContext } from '../../common/contexts/TwinContext'

// Types
import { ChartData } from '../../@types/ChartData'
import { DataMode } from '../../@types/DataMode'
import { Entity } from '../../@types/Entity'
import { FilterType } from '../../@types/FilterType'
import { onChartItemClick } from '../../@types/onChartItemClick'
import { onChartItemMouseOver } from '../../@types/onChartItemMouseOver'
import { TwinEntityType } from '../../@types/TwinEntityType'

// Utils
import { createFilterPath } from '../../common/utils/createFilterPath'
import { getDataValue } from '../../common/utils/func-metrics/getDataValue'
import { getIndicator } from '../../common/utils/getIndicator'
import { getInitials } from '../../common/utils/getInitials'
import { removeNonNumeric } from '../../common/utils/removeNonNumeric'
import { stringToTwinEntityType } from '../../common/utils/stringToTwinEntityType'
import { findEntityByBusinessId } from '../../common/utils/findEntityByBusinessId';
import { howlerTXRateData } from '../../common/data/howler/howlerTXRateData';



interface Props {
  handleMouseEnter: DebouncedState<(id: string, type: "ASSET" | "ENTITY") => void>
  handleMouseLeave: DebouncedState<() => void>
  componentName: string
  config: { xAxisLabels?: { label: string; value: number; }[]; } | undefined
  entity: Entity
  enableChartHoverSelection: boolean
}

const dynamicImport = (componentName: string) => {
    return import(`./${componentName}`)
      .then((module) => ({ default: module.default }))
      .catch((error) => {
        console.error(`Error loading component "${componentName}":`, error)
        throw error;
      });
  };

const DynamicChartRenderer: React.FC<Props> = ({
  handleMouseEnter,
  handleMouseLeave,
  componentName,
  config,
  entity,
  enableChartHoverSelection,
}) => {

  const { twin } = useTwinContext()
  const { data } = useDataContext()
  const { live, filter, startDateTime, finishDateTime, removeFilterByType, setFilter } = useFilterContext()
  const { settings } = useSettingsContext()
  const [chartData, setChartData] = useState<ChartData[]>([])

 
  const selectedAssetId = useMemo(() => {
    const result = filter.filter((item) => item.type === FilterType.ASSET)
    if (result && result.length > 0) {
      return result[result.length-1].id
    }
  }
    , [filter])

  const setFilterPath = useCallback((pathId: string) => {
      if (twin) {
          // Clear all ENTITY and ASSET filters
          removeFilterByType(FilterType.ENTITY)
          removeFilterByType(FilterType.ASSET)

          // Create the filter path
          const filter = createFilterPath(pathId, twin.model)

          // Update filter in filterContext
          setFilter(filter)
      }
  }, [twin, removeFilterByType, setFilter])


  useEffect(() => {

    const title = entity.name
    let cData: ChartData[] = []

    // If the entity has children that are Assets
    if (entity.children && entity.children.length > 0) {

      entity.children.forEach((e, i) => {

        // We should only be displaying Asset types here
        if (stringToTwinEntityType(e.type.name) === TwinEntityType.ASSET || stringToTwinEntityType(e.type.name) === TwinEntityType.OPERATOR) {

          const id = live ? e.id : e.bID
          const countEntity = data.processed?.countEntity ?  data.processed?.countEntity : []
          const dataValue = getDataValue(
              live ? DataMode.LIVE : DataMode.TIME_SERIES,
              countEntity,
              id)
          let headcount = dataValue ? dataValue.value : 0


          if (settings?.organisation === 'Howler' && startDateTime === '2024-10-12T09:00:00.000Z' && finishDateTime === '2024-10-12T11:00:00.000Z') {
            
            let result = findEntityByBusinessId(howlerTXRateData, entity.bID)

            if (result) {
              /* @ts-ignore */
              headcount = result.chart[i]
            }
          }

          const capacity = e.capacity ? e.capacity : 1

          let indicator

          if (selectedAssetId) {
              indicator = getIndicator(headcount, capacity, settings?.rangeMapping, selectedAssetId === id)
          } else {
              indicator = getIndicator(headcount, capacity, settings?.rangeMapping, true)
          }

          if (settings?.organisation === 'Howler' && startDateTime === '2024-10-12T09:00:00.000Z' && finishDateTime === '2024-10-12T11:00:00.000Z') {
            cData.push(
              {
                id: id,
                name: e.name,
                type: FilterType.ASSET,
                parentId: e.parent?.id,
                label: `Op ${i+1}`,
                value: indicator.pc,
                color: indicator.color
              }
            )
          } else {
            cData.push(
              {
                id: id,
                name: e.name,
                type: FilterType.ASSET,
                parentId: e.parent?.id,
                label: settings?.organisation === 'Howler' ? `Op ${i+1}` : e.shortName ? e.shortName : `${title && getInitials(title)}${removeNonNumeric(e.bID)}`,
                value: indicator.pc,
                color: indicator.color
              }
            )
          }

        }

      })

    // If the entity does NOT have children that are Assets but still requires a chart to be shown based on the data assoicated to it's entity ID
    } else if (entity.children && entity.children.length === 0) {

      const id = live ? entity.id : entity.bID
      const countEntity = data.processed?.countEntity ?  data.processed?.countEntity : []
      const dataValue = getDataValue(
          live ? DataMode.LIVE : DataMode.TIME_SERIES,
          countEntity,
          id)
      let headcount = dataValue ? dataValue.value : 0

      const capacity = entity.capacity
      const indicator = getIndicator(headcount ? headcount : 0, capacity ? capacity : 0, settings?.rangeMapping, true)

      cData.push(
        {
          id: entity.id,
          name: entity.name,
          type: FilterType.ENTITY,
          parentId: entity.parentId,
          label: entity.name,
          value: indicator.pc,
          color: indicator.color
        }
      )

    }
    
    setChartData(cData)

  }, [
    data,
    live,
    filter,
    settings?.rangeMapping,
    selectedAssetId,
    entity,
    entity.children,
    entity.capacity,
    entity.id,
    entity.name,
    entity.parentId,
  ])

  interface LazyComponentProps {
    config: { xAxisLabels?: { label: string; value: number; }[]; } | undefined
    data: ChartData[]
    onItemClick: (params: onChartItemClick) => void
    onItemMouseOver: (params: onChartItemMouseOver) => void
    onItemMouseOut: () => void
  }
  
  const LazyComponent: React.FC<LazyComponentProps> = useMemo(() => lazy(() => dynamicImport(componentName)), [componentName]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyComponent
        config={config}
        data={chartData}
        onItemClick={
          (params: onChartItemClick) => {
              if (params.data) {
                  const id = params.data.id;
                  setFilterPath(id)
              }
          }
      }
      onItemMouseOver={
          (params: onChartItemMouseOver) => {
              if (enableChartHoverSelection && params.data) {
                  const id = params.data.id;
                  handleMouseEnter(id, "ASSET")
              }
          }
      }
      onItemMouseOut={
          () => {
              if (enableChartHoverSelection) {
                  handleMouseLeave()
              }
          }
      }
      />
    </Suspense>
  );
}

export default DynamicChartRenderer;