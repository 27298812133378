import { useEffect, useRef, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from "axios"
import ReactECharts from 'echarts-for-react';
import { ReactComponent as SquareIcon } from '../../../icons/square.svg';
import { ReactComponent as ArrowUp } from '../../../icons/arrowUp.svg';

// Types
import { Message } from "../../../@types/Message";
import { PredefinedPrompt } from "../../../@types/PredefinedPrompt";

// Context
import { useSettingsContext } from "../../../common/contexts/SettingsContext";
import { useUserContext } from "../../../common/contexts/UserContext";

// Components
import './CopilotPrompt.css'
import TLPromptInput from "../../elements/TLPromptInput";

interface Props {
    className?: string
    apiEndpoint: string
    apiKey?: string
    triggerPrompt?: string
    addMessage: (message: Message) => void
    setAwaitingMessage: (value: boolean) => void
}

export type FormInputs = {
    message: string,
}

const formSchema = {
    message: yup.string().required(`Sorry, I didn't catch your message`),
}

const CopilotPrompt = ({ className, apiEndpoint, apiKey, triggerPrompt, addMessage, setAwaitingMessage }: Props) => {

    const { authCreds } = useUserContext()
    const { settings } = useSettingsContext()

    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm<FormInputs>({
        resolver: yupResolver(yup.object(formSchema)),
    })

    const [isGenerating, setIsGenerating] = useState(false);
    const abortControllerRef = useRef<AbortController | null>(null);



    const clearPromptInput = () => {
        setValue('message', '')
    }

    const message = watch('message', '');


    useEffect(() => {

        async function sendMessage(triggerPrompt: string) {
            await askAIQuestion(apiEndpoint, triggerPrompt, apiKey)
        }

        if (triggerPrompt) {
            sendMessage(triggerPrompt)
        }

    }, [triggerPrompt])

    const askAIQuestion = async (apiEndpoint: string, userPrompt: string, apiKey?: string) => {

        setAwaitingMessage(true);
        setIsGenerating(true); 

        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        if (apiEndpoint === '') {
            addMessage({ content: 'Sorry, I cannot answer. You need to ensure your API URL is correct.', response: true, prompts: [] })
            return
        }

        let headers = {
            'Content-Type': 'application/json',
        }

        if (apiKey) {
            headers = { ...headers, ...{ 'Authorization': `Bearer ${apiKey}` } }
        }

        axios.post(apiEndpoint, { question: userPrompt, organisation: settings?.organisation }, {
            headers: headers,
            signal: signal,
        })
            .then(response => {

                let uiResponse = ''
                let chartData = null
                let chartWidth = null
                const suggestedPrompts: PredefinedPrompt[] = []

                // Handle Flowise response
                if (response.data.text) {
                    uiResponse = response.data.text
                    // Handle TwinLabs.ai Chat response
                } else if (response.data) {

                    chartData = response.data.machine

                    let data
                    // Check if data exists on xAxis
                    if ('data' in chartData.xAxis) {
                        data = chartData.xAxis.data;
                    }
                    // Check if data exists on yAxis
                    else if ('data' in chartData.yAxis) {
                        data = chartData.yAxis.data;
                    }
                    const multiplier = 100
                    chartWidth = '800px' // Currently we fix the chart width

                    uiResponse = response.data.response ? response.data.response : ''
                }

                addMessage(
                    {
                        content: uiResponse,
                        response: true,
                        chart: chartData && chartWidth ? <ReactECharts style={{ height: `400px`, width: chartWidth }} option={chartData} /> : undefined,
                        prompts: suggestedPrompts
                    }
                )
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    addMessage({ content: "", prompts: [], response: true });
                } else {
                    addMessage(
                        {
                            content: `Something went wrong. Please try asking again.`,
                            prompts: [],
                            response: true,

                        })
                    console.error('Error:', error.response ? error.response.data : error.message);
                }
            })
            .finally(() => {
                setAwaitingMessage(false)
                setIsGenerating(false)
            })
    }

    const onSubmit: SubmitHandler<FormInputs> = async (formData) => {
        const userPrompt = formData.message
        // Do this immediately
        clearPromptInput()
        // Submit message
        submitMessage({ content: userPrompt, response: false, prompts: [] })
    }

    const submitMessage = async (message: Message) => {
        // Add user message to the conversation 
        addMessage(message)
        // Send message to AI
        await askAIQuestion(apiEndpoint, message.content, apiKey)
    }

    const handleCancelRequest = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }

    return (
        <div className={`${className} copilot-prompt`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TLPromptInput
                    props={{
                        placeholder: "Type here. 0/500",
                        type: "text",
                        ...register("message")
                    }}
                />
                {!isGenerating && <button className="submit-button" type="submit" disabled={!message}>
                    <ArrowUp/>
                </button>}
                {isGenerating && (
                    <button className="cancel-button" onClick={handleCancelRequest}>
                        <SquareIcon/>
                    </button>
                )}
            </form>
        </div>
    )
}

export default CopilotPrompt