import { DateTime } from "luxon";

export const getDifferenceBetweenDates = (startDateTime: string, finishDateTime: string, unit = 'days') => {
    let difference = 0
    const startDate = DateTime.fromISO(startDateTime)
    const endDate = DateTime.fromISO(finishDateTime)

    switch (unit) {
        case 'seconds':
            difference = endDate.diff(startDate, 'seconds').seconds
            break;
        case 'days':
            difference = endDate.diff(startDate, 'days').days + 1
            break;
        default:
            break;
    }
    return Math.floor(difference)
}