/*
 * getPercentageOfSold
 * Function Metric
 *
*/
import { DataValue } from "../../../@types/Data/DataValue"

export const getPercentageOfSold = (data: DataValue[], entityId: string): string => {
    // TODO: Need to work out the % of Sold based on entityId.
    // Need to know the amount of tickets processed for entityId and total tickets sold
    if (entityId) {
        return `0%`
    }
    return `0%`
}