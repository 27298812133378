import { ReactNode } from "react";

interface Props {
	children?: ReactNode;
}


const ExternalSite = ({ children }: Props) => {

	return (
		<>
			
            {children && <group>{children}</group>}
				
		</>
	);
};

export { ExternalSite };
