import { DateTime } from "luxon";

// Types
import { DateTimeSensorCount } from "../../../@types/DateTimeSensorCount"
import { TSData } from "../../../@types/TSData"

// Utils
import { convertUnixTimestampToDate } from "../convertUnixTimestampToDate"
import { createTSHourlySensorCountObjects } from "../createTSHourlySensorCountObjects"

export const mapTimeseriesSensorDataToDateTime = (data: TSData[], timeZone: string): DateTimeSensorCount[] => {

    let dateTimeSensorData: DateTimeSensorCount[] = []

    data.map((tsd) => {
        tsd.values.map((tsv) => {
            
            const unixTimestamp = tsv[0]
            let timeZoneDateTime = DateTime.fromSeconds(unixTimestamp, { zone: timeZone });
            const utcDateTime = DateTime.fromSeconds(unixTimestamp).toUTC();
            const dateString = timeZoneDateTime.toFormat('yyyy-MM-dd');
            const utcISO = utcDateTime.toISO()
            let timeZoneISO = timeZoneDateTime.toISO()
            let hour = timeZoneDateTime.hour
            
            let count = parseFloat(tsv[1])

            const existingDate = dateTimeSensorData.find(obj => obj.dateString === dateString);

            if (count > 0) {
                if (existingDate) {
                    existingDate.count = existingDate.count + count
                    let existingHour = existingDate.hours.find(obj => obj.hour === hour);
                    if (existingHour) {
                        existingHour.count = existingHour.count + count
                    }
                } else {

                    let hours = createTSHourlySensorCountObjects()
                    let existingHour = hours.find(obj => obj.hour === hour);
                    if (existingHour) {
                        existingHour.count = count
                    }

                    let entry: DateTimeSensorCount = {
                        utcISO: utcISO,
                        timeZoneISO: timeZoneISO,
                        dateString: dateString,
                        date: convertUnixTimestampToDate(tsv[0]),
                        hours: hours,
                        count: count
                    }
                    dateTimeSensorData.push(entry)
                }
            }
            return null
        })
        return null
    })

    return dateTimeSensorData
}