/* 
* Creates TIMESERIES Apollo Client to allow us to interact with GraphQL API endpoint
* This client is current only doing a basic request and is not currently using subscriptions/web sockets
* IMPORTANT: This needs removing and refactoring so that we only have one ApolloClient that is interchangable for the ApolloProvider
* 
*/
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Config from '../Config';

const httpLink = createHttpLink({
    uri: Config.timeSeriesGraphQLEndpoint,
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            //'X-TL-Auth': Config.liveAuthToken || '',
        },
    };
});

const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default apolloClient