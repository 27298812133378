import React, { useMemo, useState } from "react"
import {
    FullScreenMaximize16Regular,
} from "@fluentui/react-icons";
import {
    Toolbar,
    Image,
    Avatar,
    Label,
    ToolbarGroup,
    Button,
} from "@fluentui/react-components";
import { ReactComponent as CopilotLineIcon } from "../common/svg/copilot-line-icon.svg"

// Types
import type { ToolbarProps } from "@fluentui/react-components";

// Context
import { useUserContext } from "../common/contexts/UserContext";
import { useFilterContext } from "../common/contexts/FilterContext";
import TLSwitchWithIcons from "./elements/TLSwitchWithIcons";
import { useNavigate } from "react-router-dom";

interface Props {
    className?: string
    id?: string,
    size?: 'small' | 'medium' | 'large'
    props?: ToolbarProps
    fullScreen: boolean,
    supportCopilotVisible: boolean,
    setLiveMode: (value: boolean) => void
    setFullScreen: (value: boolean) => void
    setSupportCopilotVisible: (value: boolean) => void
}

const MainNav: React.FC<Props> = ({ 
    className,
    id,
    size,
    props,
    fullScreen,
    supportCopilotVisible,
    setLiveMode,
    setFullScreen,
    setSupportCopilotVisible }) => {

    const navigate = useNavigate()
    const { live, setLive } = useFilterContext()
    const { user } = useUserContext()
    const [logoOpacity, setLogoOpacity] = useState(1)

    const displayName = useMemo(() => {
        let displayName
        if (user?.firstName && user?.lastName) {
            displayName = `${user.firstName} ${user.lastName}`
        } else if (user?.organisation?.name) {
            displayName = user.organisation.name
        }
        return displayName
    }, [user]);

    return (
        <div className={className}>
            <Toolbar
                id={id}
                className="bg-glass"
                size={size ? size : "medium"}
                aria-label="Default"
                {...props}>
                <div style={{
                    height: 32,
                    marginRight: 8,
                    opacity: logoOpacity,
                    cursor: "pointer",
                    transition: "opacity 0.3s ease"
                }}
                    onClick={() => { navigate("/select-twin")}}
                    onMouseEnter={() => setLogoOpacity(0.6)}
                    onMouseLeave={() => setLogoOpacity(1)}
                    onMouseDown={() => setLogoOpacity(0.8)}
                    onMouseUp={() => setLogoOpacity(0.6)}>
                    <Image fit="contain" src="./twinlabs-ai-logo.svg" alt="Twinlabs.ai" />
                </div>
                <Label weight="semibold" className="text-sm">TwinLabs.ai</Label>
                <ToolbarGroup className="ml-auto flex">

                    <TLSwitchWithIcons
                        isSelected={live}
                        leftLabel="Analysis" 
                        rightLabel="Live" 
                        className="flex mr-2" 
                        onChange={() => {
                            setLive(!live)
                            setLiveMode(!live)
                    }}/>

                    <Button size="medium" style={{ marginRight: "8px" }} onClick={() => setFullScreen(!fullScreen)} icon={<FullScreenMaximize16Regular />}>Full Screen</Button>
                    <Button size="medium" style={{ marginRight: "8px" }} onClick={() => setSupportCopilotVisible(!supportCopilotVisible)} icon={<CopilotLineIcon />}>Support</Button>
                    <div style={{
                        width: "32px",
                        height: "32px",
                    }}
                        className="flex justify-center items-center">
                        {user && <Avatar size={20} image={{ src: user?.organisation?.logo ? user?.organisation?.logo : undefined }} name={displayName} aria-label={displayName} />}
                    </div>
                </ToolbarGroup>
            </Toolbar>
        </div>
    )
}

export default MainNav