import React, { useState } from "react"

// Types
import { PageMode } from "../@types/PageMode";

// Components
import { Image } from "@fluentui/react-components";
import Login from "../components/Login/Login";
import ResetPassword from "../components/ResetPassword";

export type FormInputs = {
    email: string,
    password: string,
};

interface Props {
    className?: string
}

const LoginPage: React.FC<Props> = ({ className }) => {
   
    
    const [pageMode, setPageMode] = useState<PageMode>(PageMode.LOGIN)
    return (
        <div className="flex h-screen"
            style={{
                backgroundImage: 'url("/assets/images/atom-bg.png")',
                backgroundSize: 'cover',
            }}
        >
            <div className="flex flex-1 flex-col p-12 lg:flex-none w-2/6">
                {pageMode === PageMode.LOGIN ? <Login setPageMode={setPageMode} /> : null }
                {pageMode === PageMode.RESET_PASSWORD ? <ResetPassword setPageMode={setPageMode} /> : null }
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
                <div className="mt-12 ml-auto mr-12" style={{width: '100px'}}>
                    <Image fit="contain" src="./twinlabs-atom-dark-logo.svg" alt="Twinlabs.ai" />
                </div>
                <div>
                    <img
                        className="object-cover"
                        src={`/assets/images/new.png`}
                        alt="Event Venue"
                    />
                </div>
            </div>
        </div>
    )
}

export default LoginPage