
/*
 * getIndicatorColor
 * 
*/

// Types
import { IndicatorColor } from "../../@types/Settings/IndicatorColor";
import { RangeMap } from "../../@types/Settings/RangeMap";

// Utils
import { evaluateStringAsFunction } from "./evaluateStringAsFunction";

export const getIndicatorColor = (value: number, config?: RangeMap[] | undefined, selected?: boolean, ) => {
    let color = IndicatorColor.NA

    value = Math.ceil(value) // Added to handle decimal values

    if (selected === false) {
        if (value === 0) {
            color = IndicatorColor.ZERO_NOT_SELECTED
        } else if (value >= 1 && value <= 10) {
            color = IndicatorColor.VERY_LOW_NOT_SELECTED
        } else if (value >= 11 && value <= 20) {
            color =  IndicatorColor.LOW_NOT_SELECTED
        } else if (value >= 21 && value <= 30) {
            color = IndicatorColor.MEDIUM_LOW_NOT_SELECTED
        } else if (value >= 31 && value <= 40) {
            color = IndicatorColor.MEDIUM_NOT_SELECTED
        } else if (value >= 41 && value <= 50) {
            color = IndicatorColor.MEDIUM_HIGH_NOT_SELECTED
        } else if (value >= 51 && value <= 75) {
            color = IndicatorColor.HIGH_NOT_SELECTED
        } else if (value >= 76 && value <= 100) {
            color = IndicatorColor.VERY_HIGH_NOT_SELECTED
        } else if (value > 100) {
            color = IndicatorColor.EXTREMELY_HIGH_NOT_SELECTED
        }
        return color
    }

    if (config) {
        for (let i = 0; i < config.length; i++) {
            // Perform test using the operation stored in the settings
            const opFunc = evaluateStringAsFunction(config[i].op);
            if (opFunc(value)) {
                color = config[i].color
                break;
            }
        }
    } else {
        if (value === 0) {
            color = IndicatorColor.ZERO
        } else if (value >= 1 && value <= 10) {
            color = IndicatorColor.VERY_LOW
        } else if (value >= 11 && value <= 20) {
            color =  IndicatorColor.LOW
        } else if (value >= 21 && value <= 30) {
            color = IndicatorColor.MEDIUM_LOW
        } else if (value >= 31 && value <= 40) {
            color = IndicatorColor.MEDIUM
        } else if (value >= 41 && value <= 50) {
            color = IndicatorColor.MEDIUM_HIGH
        } else if (value >= 51 && value <= 75) {
            color = IndicatorColor.HIGH
        } else if (value >= 76 && value <= 100) {
            color = IndicatorColor.VERY_HIGH
        } else if (value > 100) {
            color = IndicatorColor.EXTREMELY_HIGH
        }
    }
    return color
}