import { useMemo } from "react";
import { BufferGeometry } from "three";

import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import { FROSTED_CRYSTAL_GLOW } from "../theme";
import { makeLineGeometry } from "./utils";

interface Props {
	geometry: BufferGeometry;
    visible?: boolean;
}

const material = new LineMaterial({
    color: FROSTED_CRYSTAL_GLOW, 
    linewidth: 0.07,
    dashSize: 0.125, 
    gapSize: 0.125,
    dashed: true,
    worldUnits: true
});

export const OutlineDashed = ({ geometry, visible = true }: Props) => {

    const outlineObject = useMemo(()=>{

        const lineGeometry = makeLineGeometry(geometry)

        material.resolution.set(window.innerWidth, window.innerHeight);
    
        const edges = new LineSegments2( lineGeometry, material );
        edges.computeLineDistances();

        return edges
    },[geometry])

    return(
        <group >
            <primitive 
                visible={visible} 
                object={outlineObject}
            />
        </group>
    )
}





