import React from "react"
import { Card } from "@fluentui/react-components"
import './TLCard.css';

interface Props {
  className?: string
  children?: JSX.Element | JSX.Element[]
  size?: 'small' | 'medium' | 'large'
  padding?: string
  selected?: boolean
  hovered?: boolean
}

const TLCard: React.FC<Props> = ({ className, children, padding, size, selected, hovered }) => {

  return (
    <Card className={`tl-card ${className} ${selected ? 'selected' : ''} ${hovered ? 'hovered' : ''}`}
      style={{ padding: padding || '8px' }}
      size={size}
    >
        {children}
    </Card>
  )
}

export default TLCard