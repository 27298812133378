export const howlerTXRateData = [
    {
        bID: '3946',
        pc: 69,
        chart: [],
    },
    {
        bID: '87252',
        pc: 22.7,
        chart: [1.5, 1.8, 0.9, 1.2, 2.3, 1.4, 1.7]
    },
    {
        bID: '87254',
        pc: 43,
        chart: [1.4, 1.6, 0.7, 1.3, 1.8, 1.1, 0.9, 1.5, 1.7, 0.8, 1.9, 1.2, 0.6, 1.1, 1.0, 1.4, 0.5, 1.6, 1.5, 0.8, 0.9, 1.7, 1.3, 1.4, 1.8, 1.2, 0.9, 0.6, 1.1, 0.7, 1.9, 1.5, 1.3, 1.4, 0.6, 1.6, 1.0, 1.2, 1.7, 4, 1.9, 1.5, 0.7, 1.8, 1.4, 1.3, 0.5, 1.6, 1.1, 1.9, 0.9, 1.5, 1.4, 1.7, 0.8, 1.0, 2.3, 0.6, 1.2],
    },
    {
        bID: '87342',
        pc: 14.6,
        chart: [1.4, 1.8]
    },
    {
        bID: '87255',
        pc: 15.4,
        chart: [2.1, 0.5]
    },
    {
        bID: '87257',
        pc: 2.3,
        chart: [0.75]
    },
    {
        bID: '87253',
        pc: 0,
        chart: []
    },
    {
        bID: '87256',
        pc: 0,
        chart: []
    }   
]