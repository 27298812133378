/*
 * createHourObjects
 *
 * 
*/
export const createHourObjects = (startTime: string, finishTime: string) => {
    
    const startHour = parseInt(startTime.split(':')[0]);
    const finishHour = parseInt(finishTime.split(':')[0]);
    
    const hourObjects = [];

    for (let hour = startHour; hour <= finishHour; hour++) {
        const hourText = hour % 12 === 0 ? 12 : hour % 12; // Convert to 12-hour format
        const ampm = hour < 12 ? 'am' : 'pm';
        const hourValue = (hour < 10 ? '0' : '') + hour + ':00';

        hourObjects.push({
            text: `${hourText}${ampm}`,
            value: hourValue,
            hour: hour
        });
    }

    return hourObjects;
};