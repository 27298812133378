import React from "react"
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";


// Context
import { useSettingsContext } from "../common/contexts/SettingsContext";
import { useUserContext } from "../common/contexts/UserContext";

// Components
import { Card, Image } from "@fluentui/react-components";

// TODO - This is temporary and needs removing when we correctly have Twin (Site) preferences stored within the back-end
import { newportSitePrefData } from "../common/data/newport/newportSitePrefData"
import { movicoSitePrefData } from "../common/data/movico/movicoSitePrefData"
import { howlerSitePrefData } from "../common/data/howler/howlerSitePrefData";
import { Account } from "../generated/devGraphql";
import { GET_ACCOUNT } from "../common/api/live/gql/getAccount";
import { QUERY_TWIN_ENTITY } from "../common/api/live/gql/queryTwinEntity";


export type FormInputs = {
    twinId: string,
};

interface Props {
    className?: string
}

const SelectTwin: React.FC<Props> = ({ className }) => {

    const { user, setUser, authCreds, setUserPrefs, setTwinId } = useUserContext();
    const { setSettings } = useSettingsContext();
    const navigate = useNavigate()
    const apolloClient = useApolloClient()
    
    if (!authCreds) navigate("/")

    if (authCreds && !user) {
       
       apolloClient.query<{ getAccount: Account }>({
               query: GET_ACCOUNT,
               variables: { email: authCreds.email }
           }).then((response) => {
                if (!response.data) {
                    throw new Error('your account could not be found');
                } else {
                    setUser(response.data.getAccount)
                }
                
           })
    }

    if (user) {

        // TODO - This is temporary and needs removing when we correctly have Twin (Site) preferences stored within the back-end
        const setSitePref = (bID: string): boolean => {

            setUserPrefs({
                modelQuery: QUERY_TWIN_ENTITY,
                modelQueryValue: bID,
            })

            if (user.organisation.name === 'Newport Live') {
                setSettings(newportSitePrefData)
                return true
            } else if (user.organisation.name === 'Movico') {
                setSettings(movicoSitePrefData)
                return true
            } else if (user.organisation.name === 'Howler') {
                setSettings(howlerSitePrefData)
                return true
            }
            return false
        }

        return (
            <div className={className}>
                <div>
                    <div
                        style={{
                            borderRadius: "8px",
                            padding: "4rem 2rem",
                            backgroundColor: "rgb(255,255,255,0.4)",
                            borderWidth: "2px",
                            borderStyle: "solid"
                        }}>

                        <h1 style={{fontSize: '48px', lineHeight: '100%'}}>Select a digital twin</h1>
                        <p style={{ marginTop: '2rem', color: '#707070' }} className="max-w-sm">Select the twin you want to visualise.</p>

                        <div className="mt-16"> 
                            
                            {
                            // we get away with using entitites here since only the top-level entity is associated to the organisation, so in this particular query entities are twin root entities
                            !user || !user.organisation || !user?.organisation.entities || user?.organisation.entities.length === 0 ?
                                <div className="bg-red-50 border border-red-800 p-2 mt-2"><p className="text-sm text-center text-red-800">No digital twins available. Please contact support.</p></div>
                                
                                : 

                                user.organisation.entities.map((site, i) => {
                                    return (
                                        <Card
                                            className={`digital-twin-${i}`}
                                            key={i}
                                            onClick={() => {
                                                setTwinId(site.id)
                                                if (setSitePref(site.bID)) {
                                                    navigate('/')
                                                }
                                            }}
                                        >
                                            <div className="flex mr-10 justify-center items-center">
                                                <div className="p-2" style={{ width: '100px' }}>
                                                    <Image fit="contain" src="./twinlabs-atom-dark-logo.svg" alt="Twinlabs.ai" />
                                                </div>
                                                <div className="flex-1 ml-5">
                                                    <h3 style={{ fontSize: "18px" }}>{site.name}</h3>
                                                </div>
                                            </div>
                                        </Card>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }

}
      
export default SelectTwin