import React from "react"
import './TLFilterPill.css';
// Types

// Components
import { Dismiss12Regular } from "@fluentui/react-icons";

interface Props {
    label: string
    onClick: () => void
}

const TLFilterPill: React.FC<Props> = ({label, onClick}) => {
    return (
        <span className="filter-pill" onClick={onClick}>
            <p>{label}</p>
            <Dismiss12Regular />
        </span>
    )
}

export default TLFilterPill