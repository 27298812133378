/**
    * MapCanvas Component
    * 
    * The MapCanvas component integrates Mapbox and Three.js to create interactive 3D elements within the Mapbox canvas context.
    * It utilizes the React-Map-GL library for Mapbox integration and React-Three-Map & React-Three-Fiber for Three.js integration.
*/

import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGl from "mapbox-gl";
import { Map } from "react-map-gl";
import { Canvas } from "react-three-map";
import Config from "../../common/Config";
import { ThreeScene } from "./ThreeScene";
import { useTwinContext } from "../../common/contexts/TwinContext";
import { useMemo, useRef, useState } from "react";
import { DebugGlInfoDiv } from "./helpers/DebugThreeGlInfo";
import { Point } from "../../generated/devGraphql";
import { useUserContext } from "../../common/contexts/UserContext";

type Props = {
    onAllModelsLoaded: () => void
    allModelsLoaded: boolean // not used, just triggers a rerender
}


function pointsAreDifferent(point1: Point, point2: Point) {
   return point1.latitude !== point2.latitude || point1.longitude !== point2.longitude
}

/**
    * MapCanvas
    * 
    * The MapCanvas component integrates Mapbox and Three.js to create interactive 3D elements within the Mapbox canvas context.
    * It utilizes the React-Map-GL library for Mapbox integration and React-Three-Map & React-Three-Fiber for Three.js integration.
*/

const MapCanvas = ({onAllModelsLoaded }: Props) => {
    
    // Access the Twin Context to retrieve twin model information.

    const { authCreds } = useUserContext()
    const { twin } = useTwinContext();
    const infoRef = useRef<HTMLDivElement>(null) // just for debug
    const mapRef = useRef<any>(null) // to access internals of react-map-gl
    const [mapOrigin, setMapOrigin] = useState<Point | undefined>(undefined)

    if (!authCreds) throw new Error('this wont happen, the router will navigate to login if authCreds are null')

    // Assign Mapbox Auth token from the configuration.
    const mapboxToken = useMemo(()=>{
        return authCreds.tokens.mapboxToken;
    },[authCreds.tokens.mapboxToken]) 
    
    MapboxGl.accessToken = mapboxToken;

    // Detect device and browser capabilities for compatibility and performance optimizations.
    // WebGL2, VRAM, and potential future WebGPU build considerations.
    // const gl2 = WebGL.isWebGL2Available();


    // used to determine whether to render-on-demand or not
    const requiresAnimations = twin?.model.organisation?.name === "Movico"

    if (twin?.model) {
            if (twin.model.mapOrigin === undefined || twin.model.mapOrigin === null) {
                if (twin.model.organisation?.name === 'Howler') {
                    // this is a temporary dummy location for howler (the sahara desert) 
                    if (!mapOrigin) setMapOrigin({ latitude: 41.463900, longitude: 2.260000})  
                } else {
                    throw new Error(`top level TwinEntity with id ${twin.model.id} is missing mapOrigin`)
                }
                
            } else {
                if (!mapOrigin || pointsAreDifferent(mapOrigin, twin.model.mapOrigin)) setMapOrigin(twin.model.mapOrigin)
            }
        }

    
    if (!mapOrigin) return <></> 

    return (
        <>
        {Config.appProfile ==='DEBUG' && <DebugGlInfoDiv infoRef={infoRef}/>}
        <div className="fixed top-0 left-0 w-lvw h-lvh opacity-100 z-[-1] pointer-events-auto">
            
            {/* Check for Mapbox token and twin model existence. */}
            {mapboxToken && (
                <Map
                    ref={mapRef}
                    antialias

                    /* 
                        * useWebGL2 is now the default for mapbox but we may want to use WebGL1 for older device/browser support.
                        * Additionally, consider potential future webGPU build.
                        * https://caniuse.com/webgl2 ( Global Support 95.89% ) 09/03/2024
                        * https://caniuse.com/webgpu ( Global Support 25.09% ) 09/03/2024
                    */
                    // useWebGL2={gl2} 

                    /* 
                        * Set MapBox style URL for the base map visualization style.
                    */
                    mapStyle="mapbox://styles/l0bster/clutu8ah7003301picweu0wqz"
                >
                    {/* 
                        * Use the React-Three-Map canvas component to render R3F 3D components.
                    */}
                    <Canvas
                        frameloop={requiresAnimations ? "always" : "demand"}
                        latitude={mapOrigin.latitude}
                        longitude={mapOrigin.longitude}
                        overlay={true}
                        flat
                        shadows
                        performance={{ min: 0.3, max: 0.4 }}
                    >
                        {/* <axesHelper args={[100]}/> */}
                       {twin && <ThreeScene infoRef={infoRef} twin={twin} onAllModelsLoaded={onAllModelsLoaded} mapOrigin={mapOrigin} />}
                    </Canvas>
                </Map>
            )}               
        </div>
        </>
    );
};

export { MapCanvas };
