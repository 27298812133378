import React, { useCallback, useEffect, useMemo, useState } from "react"
import ReactECharts from 'echarts-for-react';
import { DateTime } from "luxon";

// Types
import { CalendarChartData } from "../../@types/CalendarChartData";
import { SelectedCellValue } from "../../@types/SelectedCellValue";
import { Week } from "../../@types/Week";

// Context
import { useTwinContext } from "../../common/contexts/TwinContext";

// Utils
import { roundToOneDecimalPlace } from "../../common/utils/roundToOneDecimalPlace";

interface Props {
    className?: string
    monthData: Week[]
    selectedCellValues: SelectedCellValue[]
}

const CalendarMonthChart: React.FC<Props> = ({ className, monthData, selectedCellValues }) => {
    
    const { twin } = useTwinContext()
    const [chartData, setChartData] = useState<CalendarChartData[]>([])
    const weekDays = useMemo(() => ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], [])

    const mapTheWeekToChartData = useCallback((monthData: Week[], twinCapacity: number) => {

        let chartData: CalendarChartData[] = []

        // First create empty chart data for each week day
        for (let i = 0; i <= 6; i++) {
            chartData[i] = {
                weekDay: weekDays[i],
                weekDayCount: 0,
                count: 0,
                usages: [],
                value: 0,
                label: '',
            }
        }

        monthData.forEach((week) => {
            week.days.forEach((day) => {
                let weekDayIndex = DateTime.fromJSDate(day.date).weekday-1 
                chartData[weekDayIndex].weekDayCount = chartData[weekDayIndex].weekDayCount + 1
                chartData[weekDayIndex].count = chartData[weekDayIndex].count + day.count
                let usageValue = twinCapacity ?(day.count / twinCapacity) * 100 : 0
                chartData[weekDayIndex].usages.push(usageValue)
                let usageSum = chartData[weekDayIndex].usages.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                let value = 0
                value = usageSum / chartData[weekDayIndex].weekDayCount
                chartData[weekDayIndex].value = value
                chartData[weekDayIndex].label = `${value}%`
            })
        })

        return chartData
    }, [weekDays])

    const mapSelectedCellValuesToChartData = (selectedCellValues: SelectedCellValue[]) => {

        const chartData: CalendarChartData[] = []

        // First create empty chart data
        for (let i = 0; i <= 6; i++) {
            chartData[i] = {
                weekDay: '',
                weekDayCount: 0,
                count: 0,
                usages: [],
                value: 0,
                label: '',
            }
        }

        selectedCellValues.forEach((scv) => {
            // Get day of week (Sunday is 0)
            let weekDayIndex = DateTime.fromISO(scv.dateTimeISO).weekday-1 
            chartData[weekDayIndex].weekDayCount = chartData[weekDayIndex].weekDayCount + 1
            chartData[weekDayIndex].count = chartData[weekDayIndex].count + scv.count
            chartData[weekDayIndex].usages.push(scv.cellValue)
            let usageSum = chartData[weekDayIndex].usages.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
            let value = 0
            value = usageSum / chartData[weekDayIndex].weekDayCount
            chartData[weekDayIndex].value = value
            chartData[weekDayIndex].label = `${value}%`
        })

        return chartData
    }


    
    useEffect(() => {
       
        let data: CalendarChartData[]
        let twinCapacity = twin?.totalCapacity

        if (selectedCellValues && selectedCellValues.length > 0) {
            data = mapSelectedCellValuesToChartData(selectedCellValues)
        } else {
            data = mapTheWeekToChartData(monthData, twinCapacity ? twinCapacity : 0)
        }

        setChartData(data)
    },
    [
        monthData,
        selectedCellValues,
        mapTheWeekToChartData,
        twin?.totalCapacity
    ])

    const option = {
        grid: {
            show: false, // Hide grid lines
            left: '0', // Adjust the left margin
            right: '0', // Adjust the right margin
            top: '0', // Adjust the top margin
            bottom: '0', // Adjust the bottom margin
          },
        xAxis: {
            type: 'category',
            data: chartData.map(item => item.label),
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        },
        series: [
            {
                data: chartData.map(item => (item.value === 0 ? null : roundToOneDecimalPlace(item.value).toFixed(1))), // Set value to null if it's 0
                type: 'bar',
                barWidth: '43px',
                barGap: 0,
                label: {
                    show: true,
                    position: 'inside',
                    formatter: function (params: { value: null; }) {
                        // Conditionally show label only if value is not null
                        return params.value !== null ? params.value : '';
                    },
                },
                itemStyle: {
                    color: function (params: { value: number; }) {
                      // Color based on value ranges
                      if (params.value < 10) {
                        return '#EAFAFA'; // Low range
                      } else if (params.value >= 10 && params.value <= 30) {
                        return '#D5F4F5'; // Mid range
                      } else {
                        return '#6CE7EF'; // High range
                      }
                    },
                }
            },

        ],
    }

    return (
        <div className={className}>
            <div className="flex">
                <div className="flex items-end">
                    <p className="text-xs font-semibold text-center">Total</p>
                </div>
                <div style={{marginLeft: '14px'}}>
                    <ReactECharts
                        className="border-b-2 border-black ml-auto"
                        style={{ height: '40px', width: '301px' }}
                        option={option}    
                    />
                </div>
            </div>
        </div>
    )
}

export default CalendarMonthChart






