import { MessageBar } from "@fluentui/react-components"
import React from "react"
interface Props {
    className?: string
}

const NotFoundPage: React.FC<Props> = ({className}) => {
    return (
        <>
        <div>
            <MessageBar>404 Page Not Found</MessageBar>
        </div>
        </>
    )
}

export default NotFoundPage