/*
 * getMaxHeadcount
 * 
*/

// Types
import { maxHeadcountByDTE } from "../../api/timeseries/maxHeadcountByDTE"

export const getMaxHeadcount = async (
    startDateTime: string,
    endDateTime: string,
    step: string = "10m",
    organisation: string,
    digitalTwinEntity: string): Promise<string> => {

    let value = 'N/A'

    if (startDateTime && endDateTime && step && digitalTwinEntity) {

        const maxHeadcounts = await maxHeadcountByDTE(startDateTime, endDateTime, step, organisation, digitalTwinEntity)

        if (maxHeadcounts && maxHeadcounts.length > 0) {
            value = `${maxHeadcounts[0].values[0][1]}`
        }

    } else {
        value = 'N/A'
    }

    return value
}