import { useState } from "react";
import {
	BufferGeometry,
} from "three";
import { Label3D } from "../../Label3D";
import { NotTracked } from "../../behaviours/NotTracked";
import { Hitbox } from "../../Hitbox";
import { useFilterContext } from "../../../../common/contexts/FilterContext";
import { SelectionOutlines } from "../../outlines/SelectionOutline";
import { TwinEntityType } from "../../../../@types/TwinEntityType";
import { LabelMode } from "../../../../@types/LabelMode";
import { Entity } from "../../../../@types/Entity";
import { determineVisibility, getMenuHovered, getSelectionCriteria } from "../../utils/filterUtils";
import { TwinEntity } from "../../../../generated/devGraphql";
import { useEventContext } from "../../../../common/contexts/EventContext";
import { Capacity } from "../../behaviours/Capacity";

interface Props {
	entity: Entity;
    mapDiv: HTMLDivElement; //Reference to the Mapbox map container element from React-Three-Map for event triggering css changes to the map cursor style.
	geometry: BufferGeometry;
	lineage: TwinEntity[]
}

/**
	* Area based on an equipment asset
    * 
*/

export const AreaFromEquipment = ({ entity, mapDiv, geometry, lineage }: Props) => {

	const { filter } = useFilterContext();
	const { currentHoveredEntity } = useEventContext()
	const [hover, setHover] = useState(false)
	const [pressed, setPressed] = useState(false)

	const parent = lineage[lineage.length - 1]
	const { filtersExist, 
		    areaIsSelected, 
			parentIsSelected,
			siblingIsSelected 
		} = getSelectionCriteria(entity, parent, filter)

	const fullLineageIncludingThisEntity = [...lineage, entity]
	const menuHovered = getMenuHovered(fullLineageIncludingThisEntity, currentHoveredEntity)

	const showSolidOutline = areaIsSelected  || pressed;
	const showDashedOutline = (hover || menuHovered) && !areaIsSelected
	const showBehaviourBoxOutline = !(showSolidOutline || showDashedOutline)

	const behavioursVisible = determineVisibility(entity, { 
		filtersExist, 
		areaIsSelected, 
		parentIsSelected, 
		siblingIsSelected, 
		isTopLevelArea: false })

	const colorMode = filtersExist && !areaIsSelected && siblingIsSelected ? 'greys' : 'colors';

	return (
			<group position={[ 0, ((entity.depth ?? 0) / 4000) , 0, ]}>
				<Hitbox 
					entity={entity}
					geometry={ geometry } 
					setHover={setHover} 
					setPressed={setPressed}
					mapDiv={mapDiv} 
					lineage={lineage}
				/>
				<SelectionOutlines geometry={geometry} showDashedOutline={showDashedOutline} showSolidOutline={showSolidOutline}/>
				{ entity.tracked ? 
				<Capacity 
					entity={entity} 
					geometry={geometry} 
					colorMode={colorMode}
					visible={behavioursVisible}
					showOutline={showBehaviourBoxOutline}
				/> 
				:
				<NotTracked 
					geometry={geometry} 
					visible={behavioursVisible} 
					showOutline={showBehaviourBoxOutline}/>
				}
				{ (hover || menuHovered || areaIsSelected) &&
					<Label3D 
					entity={entity}
					entityType={TwinEntityType.ASSET}
					labelMode={areaIsSelected ? LabelMode.FULL : LabelMode.BRIEF} 
					boundingBox={geometry.boundingBox!} 
				/>
				}
				
			</group>
	);
}


