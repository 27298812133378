export const entitySelectionPrompts = [
    {
        content: `How often is the <strong>{ENTITY}</strong> used on a daily and weekly basis?`,
    },
    {
        content: `What are the peak usage hours for the <strong>{ENTITY}</strong> throughout the day?`,
    },
    {
        content: `How does the usage frequency for the <strong>{ENTITY}</strong> vary on weekdays versus weekends?`,
    },
    {
        content: `Which days of the week see the highest and lowest usage for this <strong>{ENTITY}</strong>?`,
    },
    {
        content: `Is the <strong>{ENTITY}</strong> underutilized during certain time slots? If so, which times?`,
    }
]
