import { Vector2, Vector3 } from "three";

// to expand/contract a 2d outline
export const expandOutline = (points: Vector2[], distance: number) => {
	
	const outerPoints: Vector2[] = [];

	for (let i = 0; i < points.length; i++) {
		const current = points[i];
		const prev = points[i === 0 ? points.length - 2 : i - 1];
		const next = points[i === points.length - 1 ? 1 : i + 1];

		const incomingDirection = new Vector2(
			current.x - prev.x,
			-(current.y - prev.y)
		).normalize();

		const outGoingDirection = new Vector3(
			next.x - current.x,
			-(next.y - current.y)
		).normalize();

		const combinedDirection = new Vector2().copy(incomingDirection).add(outGoingDirection);

		if (
			(combinedDirection.x > 0 && combinedDirection.y > 0) ||
			(combinedDirection.x < 0 && combinedDirection.y < 0)
		) {
			combinedDirection.negate();
		}

		combinedDirection.multiplyScalar(distance);

		const outerPoint = new Vector2(
			current.x,
			current.y
		).sub(combinedDirection);

		outerPoints.push(new Vector2(outerPoint.x, -outerPoint.y));

	}

	return outerPoints;
}
