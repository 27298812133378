import React, { useEffect, useState } from "react";
import type { SwitchProps } from "@fluentui/react-components";
import './TLSwitchWithIcons.css';
import { ReactComponent as OnIcon } from '../../icons/switchOnIcon.svg';
import { ReactComponent as OnHoverIcon } from '../../icons/switchOnHoverIcon.svg';
import { ReactComponent as OffIcon } from '../../icons/switchOffIcon.svg';
import { ReactComponent as OffHoverIcon } from '../../icons/switchOffHoverIcon.svg';

interface Props {
  className?: string;
  leftLabel?: string;
  rightLabel?: string;
  isSelected: boolean;
  props?: SwitchProps;
  onChange?: () => void;
}

const TLSwitchWithIcons: React.FC<Props> = ({
  className,
  leftLabel,
  rightLabel,
  isSelected,
  onChange,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [currentIcon, setCurrentIcon] = useState<React.ReactNode>(null);

  useEffect(() => {
    if (isSelected) {
      setCurrentIcon(isHovered ? <OnHoverIcon /> : <OnIcon />);
    } else {
      setCurrentIcon(isHovered ? <OffHoverIcon /> : <OffIcon />);
    }
  }, [isSelected, isHovered]);


  return (
    <div
      className={`${className} tl-switch`}
    >
      {leftLabel && <label className={`switch-label ${!isSelected ? 'font-semibold': 'font-normal'}`}>
        {leftLabel}
      </label>}
      <span className="switch-icon"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onChange}>
        {currentIcon}
      </span>
      {rightLabel && <label className={`switch-label ${isSelected ? 'font-semibold': 'font-normal'}`}>
        {rightLabel}
      </label>}
    </div>
  );
};

export default TLSwitchWithIcons;
