import React from "react"

// Types
import { Entity } from "../../@types/Entity";

// Components
import { AccordionToggleData } from "@fluentui/react-components";
import AccordionItemWithContext from "./AssetAccordionItemWithContext";

interface Props {
    entities: Entity[]
    handleToggle: (isCollapsed: AccordionToggleData<string>) => void
    live: boolean
}

const AssetAccordion: React.FC<Props> = React.memo(({
    entities,
    handleToggle,
    live
}) => {
    return (
        <>
            {entities.map((entity, i) => {
                return (
                    <AccordionItemWithContext
                        key={i}
                        entity={entity}
                        handleToggle={handleToggle}
                        live={live}
                    />
                )
            })}
        </>
    )
})

export default AssetAccordion