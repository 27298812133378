import React, { useState } from "react"

// Context
import { TimelineCalendarSyncContextProvider } from '../../common/contexts/TimelineCalendarSync';
import { useSettingsContext } from "../../common/contexts/SettingsContext";

// Components
import AssetsAndAnalytics from "../AssetsAndAnalytics";
import Calendar from "../Calendar/Calendar"
import DataWarning from "../DataWarning";
import MainNav from "../MainNav";
import TLFilter from "../elements/TLFilter";
import SupportCopilot from "../SupportCopilot/SupportCopilot";
import './Frame.css';

interface Props {
    className?: string
}

const Frame: React.FC<Props> = ({ className }) => {

    const [fullScreen, setFullScreen] = useState<boolean>(false)
    const [supportCopilotVisible, setSupportCopilotVisible] = useState<boolean>(false)
    const [liveMode, setLiveMode] = useState<boolean>(true)
    const { settings } = useSettingsContext()
    
    return (
        <div id="wrapper" className={className}>
            <MainNav
                id="main-nav"
                fullScreen={fullScreen}
                supportCopilotVisible={supportCopilotVisible}
                setLiveMode={setLiveMode}
                setFullScreen={setFullScreen}
                setSupportCopilotVisible={setSupportCopilotVisible}
                />
            {!fullScreen &&
                <div className="frame">
                    <div className="left-column">
                        <AssetsAndAnalytics className="panel" liveMode={liveMode} />
                    </div>
                    <div className="centre-column">
                        <TLFilter />
                    </div>
                    <div className="right-column">
                        <div className="flex">
                        <TimelineCalendarSyncContextProvider>
                            {/* <Timeline className="panel" /> */}
                            {settings?.calendar?.visible ?
                                <Calendar className="calendar panel" />
                            : null}
                        </TimelineCalendarSyncContextProvider>
                        </div>
                    </div>
                    <div className="data-warning-indicator">
                        <DataWarning />
                    </div>
                </div>
            }
             {supportCopilotVisible && 
                <SupportCopilot
                    setSupportCopilotVisible={setSupportCopilotVisible}
                />
            }
        </div>
    )
}

export default Frame