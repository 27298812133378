import React from "react";
import {
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
} from "@fluentui/react-components";

interface Props {
    className?: string
    title: string
    dialogContent: JSX.Element
    dialogActions?: JSX.Element
    defaultOpen?: boolean
}

const TLDialog: React.FC<Props> = ({ className, title, dialogContent, dialogActions, defaultOpen }) => {
    return (
        <div className={className}>
            <Dialog defaultOpen={defaultOpen}>
                <DialogSurface>
                    <DialogBody>
                        {title ? <DialogTitle>{title}</DialogTitle> : null}
                        <DialogContent>
                            {dialogContent}
                        </DialogContent>
                        <DialogActions>
                            {dialogActions}
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    );
};

export default TLDialog