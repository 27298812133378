import React, { forwardRef } from 'react'

interface Props {
  props: React.HTMLProps<HTMLInputElement>
}

const TLPromptInput: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      props
    },
    ref
  ) => {

    return (
      <input
        ref={ref}
        {...props}
      />
    );
  });

export default TLPromptInput