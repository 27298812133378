import { useEffect, useState } from "react"

// Types
import { FilterPath } from "../../@types/FilterPath"
import { Message } from "../../@types/Message"
import { PredefinedPrompt } from "../../@types/PredefinedPrompt"

// Components
import CopilotPrompt from "./CopilotPrompt/CopilotPrompt"
import CopilotConversation from "./CopilotConversation";
import { entitySelectionPrompts } from "../../common/data/entitySelectionPrompts"

import CustomScrollbar from "../CustomScrollbar/CustomScrollbar"
import '../Frame/Frame.css';
interface Props {
  className?: string
  openingMessage?: Message
  filter?: FilterPath[]
  apiEndpoint: string
  apiKey?: string
  dynamicHeight?: boolean
  maxHeight?: number | string
}

const Copilot = ({ className, openingMessage, filter, apiEndpoint, apiKey, dynamicHeight, maxHeight }: Props) => {
  const [awaitingMessage, setAwaitingMessage] = useState<boolean>(false)
  const [triggerPrompt, setTriggerPrompt] = useState<string | undefined>()
  const [messages, setMessages] = useState<Message[]>(openingMessage ? [openingMessage] : [])

  const addMessage = (newMessage: Message) => {
    setTriggerPrompt(undefined)
    setMessages((prevMessages) => [...prevMessages, newMessage])
      if (newMessage.triggerPrompt) {
        setTriggerPrompt(newMessage.content)
      }
    }

  const clearMessages = () => {
    setMessages([])
  }

  useEffect(() => {
    if (filter && filter.length > 0) {
      let lastEntity: string = ''
      const entities = filter.filter((fp => fp.type === 0))

      if (entities && entities.length > 0) {
        entities.forEach((f) => {
          lastEntity = f.label
        })

        let prompts: PredefinedPrompt[] = []
        entitySelectionPrompts.forEach((pgp) => {
          const content = pgp.content.replaceAll(`{ENTITY}`, lastEntity)
          prompts.push({title: content, prompt: content})
        })

        addMessage({
          content: `I've spotted you've selected the <strong>${lastEntity}</strong>; here are some options:`,
          response: true,
          prompts: prompts,
        })
      }
    }

    }, [filter])

  return (
      <div className={className}>
        <CustomScrollbar maxHeight={maxHeight || "calc(100vh - 300px)"} dynamicHeight={dynamicHeight} right={-30} contentUpdated={messages.length}>
          <CopilotConversation
            className="copilot-conversation"
            messages={messages}
            awaitingMessage={awaitingMessage}
            addMessage={addMessage}
            clearMessages={clearMessages}
          />
        </CustomScrollbar>
        <CopilotPrompt
          setAwaitingMessage={setAwaitingMessage}
          addMessage={addMessage}
          triggerPrompt={triggerPrompt}
          apiEndpoint={apiEndpoint}
          apiKey={apiKey}
        />
      </div>
  );
};

export default Copilot
