export const addDataInterval = (
    date: Date,
    dataInterval: '5s' | '10s' | '15s' | '30s' | '1m' | '2m' | '5m' | '10m' | '15m' | '1h' | '2h'): Date => {
    const newDate = new Date(date)
    switch (dataInterval) {
        case '5s':
            newDate.setSeconds(newDate.getSeconds() + 5)
            break
        case '10s':
            newDate.setSeconds(newDate.getSeconds() + 10)
            break
        case '15s':
            newDate.setSeconds(newDate.getSeconds() + 15)
            break
        case '30s':
            newDate.setSeconds(newDate.getSeconds() + 30)
            break
        case '1m':
            newDate.setMinutes(newDate.getMinutes() + 1)
            break
        case '2m':
            newDate.setMinutes(newDate.getMinutes() + 2)
            break
        case '5m':
            newDate.setMinutes(newDate.getMinutes() + 5)
            break
        case '10m':
            newDate.setMinutes(newDate.getMinutes() + 10)
            break
        case '15m':
            newDate.setMinutes(newDate.getMinutes() + 15)
            break
        case '1h':
            newDate.setHours(newDate.getHours() + 1)
            break
        case '2h':
            newDate.setHours(newDate.getHours() + 2)
            break
        default:
            throw new Error('Invalid data interval')
    }
    return newDate
}