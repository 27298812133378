/*
 * generateHourlyTimestamps
 * Used to support the clearing of time filter pills
 * 
*/
export const generateHourlyTimestamps = (minDate: string, maxDate: string): {dateString: string, hourString: string}[] => {
    
    // Extract date parts
    const startDateStr = minDate.split("T")[0]; // e.g., "2024-10-21"
    const endDateStr = maxDate.split("T")[0];   // e.g., "2024-10-23"
  
    // Define start and end times
    let currentDate = `${startDateStr} 00:00`;
    const endDateTime = `${endDateStr} 23:00`;
  
    const timestamps = [];
  
    // Helper function to increment the date and time by one hour
    const incrementHour = (dateTime: string) => {
      let [date, time] = dateTime.split(" ");
      let [year, month, day] = date.split("-").map(Number);
      let [hour, minute] = time.split(":").map(Number);
  
      // Increment the hour, adjusting day/month/year if needed
      hour += 1;
      if (hour === 24) {
        hour = 0;
        day += 1;
      }
  
      // Adjust month and year if day exceeds the max days of the month
      const maxDays = new Date(year, month, 0).getDate(); // days in the current month
      if (day > maxDays) {
        day = 1;
        month += 1;
      }
      if (month > 12) {
        month = 1;
        year += 1;
      }
  
      // Format back to "YYYY-MM-DD HH:mm"
      const formattedDate = [year, String(month).padStart(2, "0"), String(day).padStart(2, "0")].join("-");
      const formattedTime = [String(hour).padStart(2, "0"), String(minute).padStart(2, "0")].join(":");
      return `${formattedDate} ${formattedTime}`;
    }
  
    // Loop until we reach end date and time
    while (currentDate <= endDateTime) {
      const [dateString, hourString] = currentDate.split(" ");
      timestamps.push({ dateString, hourString });
      currentDate = incrementHour(currentDate); // Increment by one hour
    }
  
    return timestamps;
  }