import Copilot from "../Copilot/Copilot"
import { Dismiss16Regular } from "@fluentui/react-icons"
import { ReactComponent as CopilotColorIcon } from "../../common/svg/copilot-color-icon.svg"
import Subtitle from "../Typography/Subtitle"
import { useUserContext } from "../../common/contexts/UserContext"
import './SupportCopilot.css'
import DraggablePanel from "../DraggablePanel/DraggablePanel"
import { useState } from "react"
interface Props {
  setSupportCopilotVisible: (value: boolean) => void
}

const SupportCopilot = ({ setSupportCopilotVisible }: Props) => {
  const { user } = useUserContext()

  const [isHeaderHovered, setIsHeaderHovered] = useState(false);

  const headerElement = (
    <div className="support-copilot-header" 
        onMouseEnter={() => setIsHeaderHovered(true)}
        onMouseLeave={() => setIsHeaderHovered(false)}>
      <div className="support-copilot-header-title">
        <div className="avatar">
          <CopilotColorIcon />
        </div>
        <Subtitle m="auto">Support Copilot</Subtitle>
      </div>
      <button className="close-button" onClick={() => setSupportCopilotVisible(false)}>
        <Dismiss16Regular/>
      </button>
    </div>
  );

  return (
    <DraggablePanel headerElement={headerElement} isHeaderHovered={isHeaderHovered}>
      <Copilot
        className="support-copilot"
        apiEndpoint="https://flow.twinlabs.ai/api/v1/prediction/27acef9a-1921-42e5-8e8b-5fc958a52d57"
        apiKey="h6rM8kEawj7pvMaWieh1CfdEOyXRJGnDQhd3OVSwy5Q"
        openingMessage={{
          content: `Hello ${user?.firstName}, how can I help you today?`,
          response: true,
          prompts: [],
        }}
        maxHeight={"calc(100vh - 210px)"}
        dynamicHeight
      />
    </DraggablePanel>
  );
};

export default SupportCopilot;
