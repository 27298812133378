/* 
* FilterContext
* 
*/
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { DateTime } from "luxon";

// Types
import { Filter } from "../../@types/Filter"
import { FilterType } from "../../@types/FilterType"
import { FilterPath } from "../../@types/FilterPath"
import { DateRange } from "../../@types/DateRange";

// Context
import { useSettingsContext } from "./SettingsContext"

// Utils
import { formatDateRange } from "../utils/formatDateRange"
import { formatTimeRange } from "../utils/formatTimeRange"
import { convertDateTimeISOToUTC } from "../utils/convertDateTimeToUTC";
import { isFullDay } from "../utils/isFullDay";


interface FilterContextValue {
    live: boolean
    filter: FilterPath[] // Used to when we query API to filter the dataset we get back
    startDateTime: string | null
    finishDateTime: string | null
    timeReset: boolean
    analysisReset: boolean
    setStartDateTime: (startDateTime: string | null) => void
    setFinishDateTime: (finishDateTime: string | null) => void
    setTimeReset: (resetTime: boolean) => void
    setLive: (live: boolean) => void
    setAnalysisReset: (analysisReset: boolean) => void
    setFilter: (filter: Filter[]) => void
    addFilter: (newFilter: Filter) => void
    removeFilter: (index: number) => void
    removeFilterById: (uuid: string) => void
    removeFilterByType: (type: FilterType) => void
    clearFilter: () => void
}

const initialState: FilterContextValue = {
    live: true,
    filter: [],
    startDateTime: null,
    finishDateTime: null,
    timeReset: false,
    analysisReset: false,
    setStartDateTime: () => { },
    setFinishDateTime: () => { },
    setTimeReset: () => { },
    setLive: () => { },
    setAnalysisReset: () => { },
    setFilter: () => { },
    addFilter: () => { },
    removeFilter: () => { },
    removeFilterById: () => { },
    removeFilterByType: () => { },
    clearFilter: () => { },
}

export const FilterContext = createContext<FilterContextValue>(initialState)

export const useFilterContext = (): FilterContextValue => {
    return useContext(FilterContext);
};

interface ContextProviderProps {
    children: React.ReactNode;
}

const getTodaysDateRange = (timeZone?: string) : DateRange => {

    const currentDate = DateTime.now().setZone(timeZone);
    const startTime = currentDate.startOf('day');
    const finishTime = currentDate.endOf('day');
    const startDateTimeISO = startTime.toISO({suppressMilliseconds: true, includeOffset: false})
    const finishDateTimeISO = finishTime.toISO({suppressMilliseconds: true, includeOffset: false})
    
    if (startDateTimeISO !== null && finishDateTimeISO !== null) {
        return {
            startDateTime: startDateTimeISO,
            finishDateTime: finishDateTimeISO.split('.')[0]
        }
    } else {
        throw new Error("Unable to set startDateTime and finishDateTime in FilterContext > getTodaysDateRange");
    }
}

const setInitialState = (live: boolean): FilterContextValue => {
    return {
            live: live,
            startDateTime: null,
            finishDateTime: null,
            timeReset: false,
            analysisReset: false,
            filter: [],
            setStartDateTime: () => { },
            setFinishDateTime: () => { },
            setTimeReset: () => { },
            setLive: () => { },
            setAnalysisReset: () => { },
            setFilter: () => { },
            addFilter: () => { },
            removeFilter: () => { },
            removeFilterById: () => { },
            removeFilterByType: () => { },
            clearFilter: () => { },
        }
}

export const FilterContextProvider: React.FC<ContextProviderProps> = (props) => {

    const { settings } = useSettingsContext()
    const [filterState, setFilterState] = useState<FilterContextValue>(setInitialState(settings?.startLive ? settings?.startLive : true));

    const removeFilterByType = useCallback((type: FilterType) => {
        setFilterState((prevState) => ({
            ...prevState,
            filter: prevState.filter.filter(item => item.type !== type)
        }));
    }, [])
    
    const setStartDateTime = useCallback((startDateTime: string | null) => {
        setFilterState((prevState) => ({ ...prevState, startDateTime }))
    }, [])

    const setFinishDateTime = useCallback((finishDateTime: string | null) => {
        setFilterState((prevState) => ({ ...prevState, finishDateTime }))
    }, [])

    const setTimeReset = useCallback((timeReset: boolean) => {
        setFilterState((prevState) => ({ ...prevState, timeReset }))
    }, [])

    const setDefaultTimeRange = useCallback(() => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const todayDateRange = getTodaysDateRange(settings?.timeZone ? settings?.timeZone : timeZone)
        setStartDateTime(todayDateRange.startDateTime)
        setFinishDateTime(todayDateRange.finishDateTime)
    }, [
        setFinishDateTime,
        setStartDateTime,
        settings?.timeZone,
    ])

    const setAnalysisReset = useCallback((analysisReset: boolean) => {
        setFilterState((prevState) => ({ ...prevState, analysisReset }))
        setStartDateTime(null)
        setFinishDateTime(null)
        removeFilterByType(FilterType.DATE)
        removeFilterByType(FilterType.TIME)
        setDefaultTimeRange()
    }, [
        removeFilterByType,
        setDefaultTimeRange,
        setFinishDateTime,
        setStartDateTime
    ])

    const setLive = useCallback((live: boolean) => {
        // If switched to OFFLINE (time series) mode
        if (!live && (filterState.startDateTime === null || filterState.finishDateTime === null)) {
            setDefaultTimeRange()
        // If switch to LIVE mode
        } else if (live) {
            setStartDateTime(null)
            setFinishDateTime(null)
            removeFilterByType(FilterType.DATE)
            removeFilterByType(FilterType.TIME)
        }
        setFilterState((prevState) => ({ ...prevState, live }));
    }, [
        setDefaultTimeRange,
        setStartDateTime,
        setFinishDateTime,
        removeFilterByType,
        filterState.startDateTime,
        filterState.finishDateTime,
    ])

    const addFilter = useCallback((newFilter: FilterPath) => {
        setFilterState((prevState) => (
            {
                ...prevState,
                filter: [...prevState.filter, newFilter]
            }
        ))
    }, [])

    const setFilter = useCallback((newFilter: FilterPath[]) => {
        setFilterState((prevState) => {
            // Ensure that we retain DATE and TIME FilterTypes when a user filters by entity
            const dateFilter = prevState.filter.filter(item => item.type === FilterType.DATE)
            const timeFilter = prevState.filter.filter(item => item.type === FilterType.TIME)
            return {
                ...prevState,
                filter: dateFilter.concat(timeFilter).concat(newFilter)
            }
        })
    }, [])

    const removeFilterById = useCallback((id: string) => {
        const updatedFilter = filterState.filter.filter(filter => filter.id !== id);
        setFilterState((prevState) => ({
            ...prevState,
            filter: updatedFilter,
        }));
    }, [filterState.filter])

    // Function to remove a filter and its child filters
    const removeFilter = useCallback((index: number) => {

        const filterToRemove = filterState.filter[index]
        let updatedFilter = filterState.filter.filter((_, i) => i !== index)

        // Detect any child entities to remove. We do not want orphan enties within the filter.
        if (filterToRemove.type === FilterType.ENTITY) {
            updatedFilter = updatedFilter.filter((filter, i) => {
                return !(filter.type === FilterType.ENTITY && i >= index);
            });
        }

        // If we are removing the DATE filter, then we should remove the TIME filter also
        if (filterToRemove.type === FilterType.DATE) {
            updatedFilter = updatedFilter.filter(item => item.type !== FilterType.TIME)
        }

        if (filterToRemove.type === FilterType.TIME) {
            // Get the current selected date and apply 00:00:00 and 23:59:59 to it
            if (filterState.startDateTime && filterState.finishDateTime) {
                const sdt = DateTime.fromISO(filterState.startDateTime)
                const fdt = DateTime.fromISO(filterState.finishDateTime)
                setStartDateTime(convertDateTimeISOToUTC(sdt.startOf('day').toISO()))
                setFinishDateTime(convertDateTimeISOToUTC(fdt.endOf('day').toISO()))
                setTimeReset(true)
            }
        }

        if (filterToRemove.type === FilterType.DATE) {
            // Reset and default Start/Finish Date/Time to this week
            setStartDateTime(null)
            setFinishDateTime(null)
            // The date filter is removed so put the frame in a live state
            setLive(true)
        }

        setFilterState((prevAppState) => ({
            ...prevAppState,
            filter: updatedFilter,
        }));

    }, [
        filterState.filter,
        filterState.finishDateTime,
        filterState.startDateTime,
        setTimeReset,
        setFinishDateTime,
        setLive,
        setStartDateTime,
    ])

    const clearFilter = useCallback(() => {
        setFilterState((prevState) => ({ ...prevState, filter: [] }));
        setLive(true)
    }, [setLive])

    useEffect(() => {

        if (filterState.startDateTime && filterState.finishDateTime) {

            // Initially remove alter current filters that are a FilterType of DATE or TIME (as we are replacing this)
            removeFilterByType(FilterType.DATE)
            removeFilterByType(FilterType.TIME)

            const dateFilter: FilterPath = {
                label: formatDateRange(filterState.startDateTime, filterState.finishDateTime),
                type: FilterType.DATE,
                display: true,
            }

            const timeFilter: FilterPath = {
                label: formatTimeRange(filterState.startDateTime, filterState.finishDateTime),
                type: FilterType.TIME,
                display: !isFullDay(filterState.startDateTime, filterState.finishDateTime),
            }

            setFilterState((prevState) => (
                {
                    ...prevState,
                    filter: [dateFilter, timeFilter, ...prevState.filter]
                }
            ))
       }
    }, [filterState.startDateTime, filterState.finishDateTime, removeFilterByType])

    const contextValue = useMemo(() => ({
        ...filterState,
        setLive,
        setFilter,
        addFilter,
        removeFilter,
        removeFilterByType,
        removeFilterById,
        clearFilter,
        setStartDateTime,
        setFinishDateTime,
        setTimeReset,
        setAnalysisReset,
    }), [filterState,
        setLive,
        setFilter,
        addFilter,
        removeFilter,
        removeFilterByType,
        removeFilterById,
        clearFilter,
        setStartDateTime,
        setFinishDateTime,
        setTimeReset,
        setAnalysisReset,
    ]);

    return (
        <FilterContext.Provider value={contextValue}>
            {props.children}
        </FilterContext.Provider>
    )
}