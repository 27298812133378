/*
 * timeSeriesQuery2
 * Time Series queries are similar 
 *
*/
import { INSTANT_QUERY } from './gql/instantQuery';

// Utils
import timeseriesApolloClient from '../../utils/createTimeseriesApolloClient';

export const timeSeriesInstantQuery = async (
    query: string,
    time: string,
) => {
    try {
        const { data } = await timeseriesApolloClient.query({
            query: INSTANT_QUERY,
            variables: {
                query: query,
                time: time,
            }
        })
        return data.instantQuery.value
    } catch (error) {
        throw new Error("timeSeriesInstantQuery: Unable to receive a range query response from the time series");
    }
}