import { MessageBar } from "@fluentui/react-components"
import React from "react"

interface Props {
    className?: string
}

const UnavailablePage: React.FC<Props> = ({className}) => {
    return (
    <>
        <div>
            <MessageBar>This page is unavailable</MessageBar>
        </div>
    </>
    )
}

export default UnavailablePage