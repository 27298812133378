import { Object3D, Vector3 } from "three";


export const DIRECTION_LIGHT_POSITION = new Vector3(10, 8, 12)
export const DIRECTION_LIGHT_TARGET = new Object3D()

const LightsAndShadows = ({indoor}: {indoor: boolean}) => {

	const settings = indoor ? {
		// indoor
		ambientIntensity: 2,
		directionalIntensity: 2,
		directionalColor: 0xFFFFFF
	} : {
		// outdoor
		ambientIntensity: 2,
		directionalIntensity: 2,
		directionalColor: 0xFFFFFF
	}

	return(
		<>
			<directionalLight
				castShadow
				shadow-bias={-0.003}
				position={DIRECTION_LIGHT_POSITION}
				color={settings.directionalColor}
				intensity={settings.directionalIntensity}
				shadow-mapSize={1024}
				target={DIRECTION_LIGHT_TARGET}
			>
				<orthographicCamera
					attach="shadow-camera"
					args={[0, 45, -10, 20, 0.1, 50]}
				/>
			</directionalLight>
			<ambientLight 
				intensity={settings.ambientIntensity}
			/>
		</>
	)
}

export { LightsAndShadows };
