
/*
 * parseTwin
 *
*/

// Types
import { Entity } from "../../@types/Entity";
import { LogEntry } from "../../@types/LogEntry";
import { Entity as EntityPref } from "../../@types/Settings/Entity"
import { Twin } from "../../@types/Twin";

// Utils
import { checkEntityDataIntegrity } from "./checkEntityDataIntegrity";

export const parseTwin = (organisation: string, twinEntity: Entity, preFlightCheckEnabled: boolean = true, apolloExplorerURI?: string, entityPrefs?: EntityPref): {twin: Twin, preFlightReport: LogEntry[]}  => {

    let preFlightReport: LogEntry[] = []

    const exploreModel = (twinEntity: Entity, parentId: string | null, parentName: string | null, depth: number = 0, ) => {

        if (preFlightCheckEnabled) {
            preFlightReport = preFlightReport.concat(checkEntityDataIntegrity(organisation, twinEntity, apolloExplorerURI ? apolloExplorerURI : '', depth === 0))
        }

        const entity: Entity = { ...twinEntity, depth: depth, parentId: parentId ? parentId : undefined, parentName: parentName ? parentName : undefined };
        const children: Entity[] = entity.children ? entity.children.map(child => exploreModel(child as Entity, entity.id, entity.name, depth + 1)) : []
        return { ...entity, children }
    }

    // Start exploring the response from the full site query
    const model = exploreModel(twinEntity, null, null);
    const twin = {model: model, totalCapacity: model.capacity ? model.capacity : 0}

    return {
        twin: twin,
        preFlightReport: preFlightReport,
    }
}