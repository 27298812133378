/*
 * getCumulativeCount
 * Function Metric
 *
*/
import { DataValue } from "../../../@types/Data/DataValue"

export const getCumulativeCount = (data: DataValue[], entityId: string): string => {
    // TODO: Use entityID to work out cumulative count. Need data.
    if (entityId) {
        return `0`
    }
    return `0`
}