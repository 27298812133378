/*
 * timeSeriesQuery
 * Time Series queries are similar 
 *
*/
import { TIME_SERIES_RANGE_QUERY } from './gql/timeSeriesRangeQuery';

// Utils
import timeseriesApolloClient from '../../utils/createTimeseriesApolloClient';

export const timeSeriesRangeQuery = async (
    query: string,
    startDateTime: string,
    endDateTime: string,
    step: string = "15m"
) => {
    try {
        const { data } = await timeseriesApolloClient.query({
            query: TIME_SERIES_RANGE_QUERY,
            variables: {
                query: query,
                startTime: startDateTime,
                endTime: endDateTime,
                step
            }
        })
        return data.rangeQuery.result
    } catch (error) {
        throw new Error("timeSeriesRangeQuery: Unable to receive a range query response from the time series");
        
    }
}