// Types
import { Label } from "../../../../../@types/Settings/Label"
import { LabelValueType } from "../../../../../@types/Settings/LabelValueType"

// Utils
import { getCumulativeCount } from "../../../../utils/func-metrics/getCumulativeCount"

export const LabelSetEventChuteLiveBrief: Label[] = [
    {
        name: 'Cumulative Count',
        valueName: 'getCumulativeCount',
        valueType: LabelValueType.FUNC_METRIC,
        value: getCumulativeCount,
    },
]