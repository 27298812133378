import React from "react"

// Context
import { useFilterContext } from "../common/contexts/FilterContext";
import { useSettingsContext } from "../common/contexts/SettingsContext"

// Components
import Copilot from "./Copilot/Copilot"

interface Props {
    className?: string
    expandPanel: () => void
}

const AnalyticsCopilot = ({ className, expandPanel }: Props) => {

    const { filter } = useFilterContext()
    const { settings } = useSettingsContext()

    return (
        <div className={className}>
            <Copilot
                apiEndpoint="https://ai.twinlabs.ai/chat"
                openingMessage={{
                    content: "**IMPORTANT: This feature is in beta.** Hello, I'm your friendly TwinLabs AI Data Analyst! Ask me anything about your data. Here are some ideas to get you started..",
                    response: true,
                    prompts: settings?.analytics?.introPrompts,
                }}
                //filter={filter}
             />
        </div>
    )
}

export default AnalyticsCopilot