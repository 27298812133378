/*
 * sumWindSpeed
 * This is a wrapper for the timeSeriesRangeQuery function
 * Returns Sum Wind Speed Hero Metric
 * 
*/
import { timeSeriesRangeQuery } from "./timeSeriesRangeQuery"

export const sumWindSpeed = async (
    startDateTime: string,
    endDateTime: string,
    step: string = "15m",
    organisation: string
) => {

    let filterBy = `metric='windSpeed'`

    if (organisation) {
        filterBy = filterBy + `,organisation='${organisation}'`
    }

    const query = `sum by(digitalTwinEntity, digitalTwinEntityPath, metric) (sum_over_time(metricvalue{${filterBy}}[${step}]))`
    return timeSeriesRangeQuery(query, startDateTime, endDateTime, step)
}