/*
 * exportCSV
 * Exports CSV to downloads folder
 * 
*/
import { saveAs } from "file-saver"
import { unparse } from "papaparse";

export const exportCSV = (data: any, fileName: string = 'data.csv') => {
  const csv = unparse(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, fileName);
};