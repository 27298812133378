/*
 * getTransactionRate
 * Function Metric
 *
*/
import { DataValue } from "../../../@types/Data/DataValue"

export const getTransactionRate = (data: DataValue[], entityId: string, interval: number = 15): string => {
    // TODO: Need to work out the transaction rate. e.g. 4 per [selected time window] (interval). Need data.
    if (entityId) {
        return `0`
    }
    return `0`
}