/*
 * formatDateRange
 * Used to format Filter Pill date ranges
 * 
*/
import { DateTime } from "luxon";

// Utils
import { getOrdinalSuffix } from "./getOrdinalSuffix";

export const formatDateRange = (startDateISO: string, endDateISO: string) => {

    const drParts = extractDateRangeParts(startDateISO, endDateISO)

    let startDay = drParts.startDay
    let finishDay = drParts.finishDay
    let startMonth = drParts.startMonth
    let finishMonth = drParts.finishMonth
    let startYear = drParts.startYear
    let finishYear = drParts.finishYear

    const startDayString = `${startDay}${getOrdinalSuffix(startDay)}`
    const finishDayString = `${finishDay}${getOrdinalSuffix(finishDay)}`

    let output

    // e.g. 01/12/2024 and 01/01/2025 formats to "1st Dec 2024-1st Jan 2025"
    if (startYear !== finishYear) {
      output = `${startDayString} ${startMonth} ${startYear}-${finishDayString} ${finishMonth} ${finishYear}`
    // e.g. 01/07/2024 and 01/07/2024 formats to "1st Jul 2024"
    } else if (startYear === finishYear && startDay === finishDay && startMonth === finishMonth) {
      output = `${startDayString} ${startMonth} ${startYear}`
    // e.g. 01/07/2024 and 01/08/2024 formats to "1st Jul-1st Aug 2024"
    } else if (startYear === finishYear && startMonth !== finishMonth) {
      output = `${startDayString} ${startMonth}-${finishDayString} ${finishMonth} ${startYear}`
    // e.g. 01/07/2024 and 11/07/2024 formats to "1st-11th Jul 2024"
    } else if (startYear === finishYear && startDay !== finishDay && startMonth === finishMonth) {
      output = `${startDayString}-${finishDayString} ${startMonth} ${startYear}`
    }

    return `${output}`

  };

  const extractDateRangeParts = (startDateISO: string, endDateISO: string) => {
    
    // Parsing the start and end ISO date strings into DateTime objects
    const startDate = DateTime.fromISO(startDateISO);
    const endDate = DateTime.fromISO(endDateISO);

    // Extracting the day from each date
    const startDay = startDate.day;
    const finishDay = endDate.day;
    
    // Extracting and formatting the month (e.g., Jul)
    const startMonth = startDate.toFormat('MMM');
    const finishMonth = endDate.toFormat('MMM');
    
    // Extracting the year
    const startYear = startDate.year;
    const finishYear = endDate.year;
    
    return {
        startDay,
        finishDay,
        startMonth,
        finishMonth,
        startYear,
        finishYear
    };
}