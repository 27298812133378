import React, { useEffect, useState } from "react"

// Types
import { Entity } from "../../@types/Entity";
import { FilterType } from "../../@types/FilterType";

// Context
import { useFilterContext } from "../../common/contexts/FilterContext";
import { useTwinContext } from "../../common/contexts/TwinContext";

// Utils
import { createFilterPath } from "../../common/utils/createFilterPath";

// Components
import { Accordion, AccordionToggleData } from "@fluentui/react-components";
import AssetAccordion from "./AssetAccordion";

interface Props {
    className?: string
}

const Assets2: React.FC<Props> = ({ className }) => {

    const { twin } = useTwinContext()

    let renderTwin: { children: Entity[] | null } = { children: [] }
    if (twin && twin.model) {
        renderTwin.children?.push(twin.model)
    }

    const { filter, live, setFilter, removeFilterByType } = useFilterContext()
    const [openItems, setOpenItems] = useState<string[]>([])

    useEffect(() => {
        let openedFilterItems: string[] = []
        filter.forEach(f => {
            f.id && openedFilterItems.push(f.id)
        })
        setOpenItems(openedFilterItems)
    }, [filter])

    const handleToggle = (isCollapsed: AccordionToggleData<string>) => {

        let pathId = isCollapsed.value

        const lastPathId = isCollapsed.openItems[isCollapsed.openItems.length - 1]
        let firstItemClosed = isCollapsed.openItems.length === 0

        if (!isCollapsed.openItems.includes(pathId) && filter[0].id === lastPathId) {
            firstItemClosed = true
        }

        if (!isCollapsed.openItems.includes(isCollapsed.value)) {
            pathId = lastPathId
        }

        // Clear all TAG and ASSET Filter Pills
        removeFilterByType(FilterType.ENTITY)
        removeFilterByType(FilterType.ASSET)

        if (twin) {
            // Create the filter path
            const filter = createFilterPath(pathId, twin.model)
            // Update filter in filterContext
            setFilter(filter)
        }
    }

    return (
        <div className={className}>
            <Accordion<string>
                onToggle={(event, isCollapsed) => handleToggle(isCollapsed)}
                openItems={openItems}
                collapsible={true}
                multiple={true}
            >
                <AssetAccordion
                    entities={renderTwin.children ? renderTwin.children : []}
                    handleToggle={handleToggle}
                    live={live}
                />
            </Accordion>
        </div>
    )
}

export default Assets2