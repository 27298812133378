import { Image } from "@fluentui/react-components";
import SelectTwin from "../components/SelectTwin";

const SelectTwinPage = () => {

    return (
        <div className="flex h-screen"
            style={{
                backgroundImage: 'url("/assets/images/atom-bg.png")',
                backgroundSize: 'cover',
            }}
        >
            <div className="flex flex-1 flex-col p-12 lg:flex-none w-2/6">
                <SelectTwin />
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
                <div className="mt-12 ml-auto mr-12" style={{width: '100px'}}>
                    <Image fit="contain" src="./twinlabs-atom-dark-logo.svg" alt="Twinlabs.ai" />
                </div>
                <div>
                    <img
                        className="object-cover"
                        src={`/assets/images/new.png`}
                        alt="Event Venue"
                    />
                </div>
            </div>
        </div>
    )
}

export default SelectTwinPage