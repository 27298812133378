import React, { useEffect, useRef, useState } from "react"
import { DateTime } from "luxon";

// Context
import { useFilterContext } from "../../common/contexts/FilterContext"
import { useSettingsContext } from "../../common/contexts/SettingsContext"
import { useTwinContext } from "../../common/contexts/TwinContext"

// Types
import { DateTimeSensorCount } from "../../@types/DateTimeSensorCount"
import { SelectedCellValue } from "../../@types/SelectedCellValue";
import { Week } from "../../@types/Week"
import { SelectionBoxPosition } from "../../@types/SelectionBoxPosition";
import { SelectedCells } from "../../@types/SelectedCells";

// Data
import { timeSeriesQuery } from "../../common/api/timeseries/tsRangeQuery";

// Utils
import { findMinMaxDates } from "../../common/utils/findMinAndMaxDates"
import { generateCalendarMonth } from "../../common/utils/generateCalendarMonth"
import { getIndicator } from "../../common/utils/getIndicator"
import { mapTimeseriesSensorDataToDateTime } from "../../common/utils/mappers/mapTimeseriesSensorDataToDateTime"
import { convertDateTimeISOToUTC } from "../../common/utils/convertDateTimeToUTC";
import { findObjectByPropertyValue } from "../../common/utils/findObjectByPropertyValue";
import { isSameDate } from "../../common/utils/isSameDate";

// Components
import CalendarMonthChart from "./CalendarMonthChart"
import CalendarSelectionOverlay from "./CalendarSelectionOverlay";
import MonthStepper from "./MonthStepper"


interface Props {
    className?: string
    data?: any[]
}

const CalendarMonthView: React.FC<Props> = ({ className }) => {

    const { analysisReset, setStartDateTime, setFinishDateTime, setLive, setAnalysisReset } = useFilterContext()
    const { settings } = useSettingsContext()
    const { twin } = useTwinContext()

    const currentDateTime = new Date()
    const timeZone = settings?.timeZone ? settings.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
    const [monthViewDateTime, setMonthViewDateTime] = useState<Date>(currentDateTime)
    const [calendarMonth, setCalendarMonth] = useState<Week[]>([])

    let totalCap = 0
    if (twin && twin.totalCapacity) {
        totalCap = twin.totalCapacity
    }

    if (totalCap === 0) {
        throw new Error("Unable to render MonthView calendar. Digital Twin is missing total capacity");
    }

    const updateDateCount = (dateToUpdate: string, count: number) => {
        setCalendarMonth(prevCalendarMonth => {
            return prevCalendarMonth.map(week => {
                const updatedDays = week.days.map(day => {
                    if (day.dateString === dateToUpdate) {
                        // Update the count for the specific date
                        return { ...day, count: count };
                    }
                    return day;
                });
                return { ...week, days: updatedDays };
            });
        });
    };

    useEffect(() => {

        if (monthViewDateTime) {

            const fetchCalendarData = async (startDate: string, finishDate: string) => {

                // Get month view range using a combination of startDate and finishDate and the time zone the twin is located in
                let startDateTwinZone = DateTime.fromISO(startDate).setZone(timeZone)
                let finishDateTwinZone = DateTime.fromISO(finishDate).setZone(timeZone).endOf('day')

                const startDateISO = startDateTwinZone.toISO()
                const finishDateISO = finishDateTwinZone.toISO()
                let queryStartDate
                let queryFinishDate

                if (startDateISO && finishDateISO) {
                    queryStartDate = convertDateTimeISOToUTC(startDateISO)
                    queryFinishDate = convertDateTimeISOToUTC(finishDateISO)
                }

                // Convert the twin time zone date to UTC
                if (settings && queryStartDate && queryFinishDate) {
                    
                    let data = await timeSeriesQuery(queryStartDate, queryFinishDate, settings.heroMetrics[0].aggregation, '24h', settings.organisation, settings.heroMetrics[0].metric, settings.heroMetrics[0].metricType, twin?.model.bID, '-24h')

                    // Map sensor data to DateTime
                    const dateTimeSensorData: DateTimeSensorCount[] = mapTimeseriesSensorDataToDateTime(data, timeZone)

                    // Hydrate the calendar month with sensor data
                    dateTimeSensorData.forEach(entry => {
                        updateDateCount(entry.dateString, entry.count)
                    });
                }
            }

            // 1. Generate calendar month
            const calendarMonth = generateCalendarMonth(monthViewDateTime)
            /* @ts-ignore */
            setCalendarMonth(calendarMonth)

            // 2. Use the start/finish date from the calendar month to fetch the sensor data
            let startDate
            let finishDate

            if (Array.isArray(calendarMonth)) {
                startDate = calendarMonth[0].days[0].dateString
                finishDate = calendarMonth[4].days[6].dateString
            }

            if (startDate && finishDate) {
                fetchCalendarData(startDate, finishDate)
            }

        }

    }, [
        timeZone,
        twin?.model.bID,
        monthViewDateTime,
        settings
    ])

    const setMonth = (currentMonth: Date) => {
        setMonthViewDateTime(currentMonth)
        resetSelection()
    }

    // CALENDAR
    const cellWidthModifier = 43
    const cellHeight = 32
    //const selectionBoxBorderOffset = 2
    const [isDragging, setIsDragging] = useState(false)

    const [currentSelectedValues, setCurrentSelectedValues] = useState<SelectedCellValue[]>([]);
    const [selectedCells, setSelectedCells] = useState<SelectedCells>({lastModified: DateTime.now().toISO(), selectedValues: []});
    const [selectedDateTimes, setSelectedDateTimes] = useState<string[]>([])
    const [hideSelectionBox, setHideSelectionBox] = useState<boolean>(false)

    const [selectionBox, setSelectionBox] = useState({
        startX: 0,
        startY: 0,
        width: 0,
        height: 0,
        borderStyle: 'dashed',
    });

    const [initialMouseY, setInitialMouseY] = useState(0) // Track initial Y position
    const [initialMouseX, setInitialMouseX] = useState(0) // Track initial X position
    const gridRef = useRef<HTMLTableElement>(null)
    const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

    // AnalysisReset: Triggered when the analysis mode is reset
    useEffect(() => {
        if (analysisReset) {
            resetSelection()
            setAnalysisReset(false)
        }
    }, [
        analysisReset,
        setAnalysisReset
    ])

    const handleMouseDown = (value: string, event: React.MouseEvent<HTMLTableCellElement>) => {

        setHideSelectionBox(false)

        if (!gridRef.current) return;
        const gridRect = gridRef.current.getBoundingClientRect();
        const { clientX, clientY } = event
        const relativeX = clientX - gridRect.left
        const relativeY = clientY - gridRect.top

        setIsDragging(true)
        setInitialMouseY(relativeY) // Set initial Y position
        setInitialMouseX(relativeX) // Set initial X position
        setSelectionBox({
            startX: relativeX,
            startY: relativeY,
            width: 0,
            height: 0,
            borderStyle: 'dashed',
        });

        setSelectedCells({lastModified: DateTime.now().toISO(), selectedValues: []})
    }

    const handleMouseMove = (event: React.MouseEvent<HTMLTableElement, MouseEvent>) => {

        if (!isDragging || !gridRef.current) return
        const { clientX, clientY } = event
        const sbp = calcSelectionBoxPosition(gridRef, clientX, clientY, calendarMonth.length)

        if (sbp) {

            // Only proceed if the mouse is moving downward and to the right
            if (sbp.relativeY > initialMouseY && sbp.relativeX > initialMouseX) {

                const selectedDateTimes: string[] = []

                for (let row = Math.min(sbp.startRow, sbp.endRow); row < Math.max(sbp.startRow, sbp.endRow); row++) {
                    for (let col = Math.min(sbp.startCol, sbp.endCol); col < Math.max(sbp.startCol, sbp.endCol); col++) {

                        let week = calendarMonth[row - 1]
                        let day = week.days[col - 1]

                        let selectedDateTime = DateTime.fromJSDate(day.date)
                        let selectedDateTimeISO = selectedDateTime.toISO({ suppressMilliseconds: true, includeOffset: false })

                        selectedDateTimes.push(selectedDateTimeISO ? selectedDateTimeISO : '')

                        let dateTimeISO = `${selectedDateTimeISO}`
                        let indicator = getIndicator(day.count, totalCap, settings?.rangeMapping)

                        let selecteCellValue: SelectedCellValue = {
                            dateTimeISO: dateTimeISO,
                            count: day.count,
                            cellValue: indicator.pc
                        }
                        setSelectedCellValue(selecteCellValue)
                    }
                }

                setSelectedDateTimes(selectedDateTimes)

                setSelectionBox({
                    startX: sbp.snapStartX,
                    startY: sbp.snapStartY,
                    width: Math.abs(sbp.snapEndX - sbp.snapStartX),
                    height: Math.abs(sbp.snapEndY - sbp.snapStartY),
                    borderStyle: 'dashed',
                })
                
            }
        }
    }

    const handleMouseClick = (event: any) => {

        if (!gridRef.current) return
        const { clientX, clientY } = event
        const sbp = calcSelectionBoxPosition(gridRef, clientX, clientY, calendarMonth.length)

        if (sbp) {
            const selectedDateTimes: string[] = []

            for (let row = Math.min(sbp.startRow, sbp.endRow); row < Math.max(sbp.startRow, sbp.endRow); row++) {
                for (let col = Math.min(sbp.startCol, sbp.endCol); col < Math.max(sbp.startCol, sbp.endCol); col++) {

                    let week = calendarMonth[row - 1]
                    let day = week.days[col - 1]

                    let selectedDateTime = DateTime.fromJSDate(day.date)
                    let selectedDateTimeISO = selectedDateTime.toISO({ suppressMilliseconds: true, includeOffset: false })

                    selectedDateTimes.push(selectedDateTimeISO ? selectedDateTimeISO : '')

                    let dateTimeISO = `${selectedDateTimeISO}`
                    let indicator = getIndicator(day.count, totalCap, settings?.rangeMapping)

                    let selecteCellValue: SelectedCellValue = {
                        dateTimeISO: dateTimeISO,
                        count: day.count,
                        cellValue: indicator.pc
                    }
                    setSelectedCellValue(selecteCellValue, true)
                }
            }

            setSelectedDateTimes(selectedDateTimes)

            setSelectionBox({
                startX: sbp.snapStartX,
                startY: sbp.snapStartY,
                width: Math.abs(sbp.snapEndX - sbp.snapStartX),
                height: Math.abs(sbp.snapEndY - sbp.snapStartY),
                borderStyle: 'solid',
            })
        }
    }

    const handleMouseUp = () => {
        setIsDragging(false)
        let finalSelectedValues: SelectedCellValue[] = []
        selectedCells.selectedValues.forEach((scv) => {
            if (selectedDateTimes.includes(scv.dateTimeISO)) {
                finalSelectedValues.push(scv)
            }
        })
        setCurrentSelectedValues(finalSelectedValues)
        setSelectionBox({...selectionBox, borderStyle: 'solid'})
    }

    const resetSelection = () => {
        setSelectedCells({lastModified: DateTime.now().toISO(), selectedValues: []})
        setCurrentSelectedValues([])
        setSelectedDateTimes([])
        setHideSelectionBox(true)
    }

    const setSelectedCellValue = (scv: SelectedCellValue, singleCell: boolean = false) => {
        if (singleCell) {
            setCurrentSelectedValues([scv])
        } else {
            const foundObj = findObjectByPropertyValue(selectedCells.selectedValues, 'dateTimeISO', scv.dateTimeISO)
            if (!foundObj) {
                setSelectedCells({lastModified: DateTime.now().toISO(), selectedValues: [...selectedCells.selectedValues, scv]})
            }
        }
    }

    const calcSelectionBoxPosition = (
        gridRef: React.RefObject<HTMLTableElement>,
        clientX: number,
        clientY: number,
        numOfWeeks: number,
    ): SelectionBoxPosition | void => {

        if (!gridRef.current) return

        const gridRect = gridRef.current.getBoundingClientRect()
        const relativeX = clientX - gridRect.left
        const relativeY = clientY - gridRect.top

        // Calculate adjusted cell width
        const adjustedCellWidth = (gridRect.width - cellWidthModifier) / 7;

        // Calculate adjusted cell height
        const adjustedCellHeight = (gridRect.height - gridRef.current.querySelector('thead')!.clientHeight) / numOfWeeks;

        // Calculate the cell indices for the start and end of the selection box
        const startCol = Math.floor(initialMouseX / adjustedCellWidth)
        const startRow = Math.floor(initialMouseY / adjustedCellHeight)
        const endCol = Math.ceil(relativeX / adjustedCellWidth)
        const endRow = Math.ceil(relativeY / adjustedCellHeight)

        // Calculate snapping positions
        const snapStartX = startCol * adjustedCellWidth
        const snapStartY = startRow * adjustedCellHeight
        const snapEndX = endCol * adjustedCellWidth
        const snapEndY = endRow * adjustedCellHeight

        return {
            relativeX: relativeX,
            relativeY: relativeY,
            startCol: startCol,
            startRow: startRow,
            endCol: endCol,
            endRow: endRow,
            snapStartX: snapStartX,
            snapStartY: snapStartY,
            snapEndX: snapEndX,
            snapEndY: snapEndY,
        }
    }

    useEffect(() => {

        if (!analysisReset && !isDragging && selectedDateTimes && selectedDateTimes.length > 0) {

            const minMaxDates = findMinMaxDates(selectedDateTimes, timeZone)

            if (minMaxDates.minDate && minMaxDates.maxDate) {

                let selectedStartDateTime = DateTime.fromISO(minMaxDates.minDate).setZone(timeZone)
                let selectedFinishDateTime = DateTime.fromISO(minMaxDates.maxDate).setZone(timeZone).endOf('day')
                selectedFinishDateTime = selectedFinishDateTime.endOf('day').set({ millisecond: 0 })

                const startDateTimeISO = selectedStartDateTime.toISO()
                const finishDateTimeISO = selectedFinishDateTime.toISO()

                if (startDateTimeISO && finishDateTimeISO) {
                    setLive(false)
                    setStartDateTime(convertDateTimeISOToUTC(startDateTimeISO))
                    setFinishDateTime(convertDateTimeISOToUTC(finishDateTimeISO))
                }
            }
        }
    }, [
        analysisReset,
        isDragging,
        selectedDateTimes,
        timeZone,
        setLive,
        setStartDateTime,
        setFinishDateTime,
    ]);

    return (
        <div className={className}>
            <CalendarMonthChart monthData={calendarMonth} selectedCellValues={currentSelectedValues} />
            <MonthStepper monthViewDateTime={monthViewDateTime} setMonth={setMonth} />
            {monthViewDateTime &&
                <div>
                    <div style={{ marginTop: '1rem', position: 'relative' }} onMouseUp={handleMouseUp}>
                        <table id="duration" ref={gridRef} onMouseMove={handleMouseMove}>
                            <thead>
                                <tr>
                                    <th></th>
                                    {weekDays.map((wd, i) => {
                                        return (
                                            <th key={i}
                                                style={{
                                                    verticalAlign: 'middle',
                                                    width: `${cellWidthModifier}px`,
                                                    height: `${cellHeight}px`,
                                                    fontSize: '10px',
                                                    textAlign: 'center',
                                                    color: '#7A7A7A'
                                                }}>
                                                {wd}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {calendarMonth.map((cm, i) => {
                                    return (
                                        <tr key={i}>
                                            <th
                                                style={{
                                                    verticalAlign: 'middle',
                                                    width: `${cellWidthModifier}px`,
                                                    height: `${cellHeight}px`,
                                                    fontSize: '10px',
                                                    fontWeight: 'normal',
                                                    textAlign: 'center'
                                                }}
                                            >W{cm.weekNo}</th>
                                            {cm.days.map((day, i2) => {

                                                const cellValue = `${day.dateString}T00:00:00`
                                                let selected

                                                if (selectedDateTimes && selectedDateTimes.length === 0) {
                                                    selected = true
                                                } else {
                                                    selected = selectedDateTimes.includes(cellValue)
                                                }

                                                let indicator = getIndicator(day.count, totalCap, settings?.rangeMapping, selected)
                                                let currentDayStyle = isSameDate(currentDateTime, new Date(day.dateString)) ? {borderBottom: `2px`, borderStyle: 'solid', borderBottomColor: 'rgba(228, 255, 254, 1)'} : {}

                                                return (
                                                    <td
                                                        key={i2}
                                                        data-value={cellValue}
                                                        style={{ width: `${cellWidthModifier}px`, height: `${cellHeight}px`, backgroundColor: indicator.color, ...currentDayStyle }}
                                                        onClick={(event) => handleMouseClick(event)}
                                                        onMouseDown={(event) => handleMouseDown(cellValue, event)}
                                                    >
                                                        {indicator.pc > 0 ? `${indicator.pc.toFixed(1)}%` : `-`}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                        {!hideSelectionBox && gridRef && gridRef.current && selectionBox.width !== 0 && selectionBox.height !== 0 && (
                            <div
                                className="selection-box"
                                onClick={() => {
                                    setAnalysisReset(true)
                                }}
                                style={{
                                    position: 'absolute',
                                    left: selectionBox.startX,
                                    top: selectionBox.startY,
                                    width: selectionBox.width,
                                    height: selectionBox.height,
                                    borderStyle: selectionBox.borderStyle,
                                }}
                            >
                                {!isDragging && <CalendarSelectionOverlay selectedCellValues={currentSelectedValues} />}
                            </div>
                        )}
                    </div>
                </div>}
        </div>
    )
}

export default CalendarMonthView