/*
 * sumMetricData
 * Performs queries and returns raw data for all Metrics
 * 
*/
// Data
import { TSMetricData } from "../../../@types/Data/TSMetricData";

// Utils
import { sumHeadcount } from "./sumHeadcount";
import { sumWindOrientation } from "./sumWindOrientation";
import { sumWindSpeed } from "./sumWindSpeed";

export const sumMetricData = async (
    startDateTime: string,
    endDateTime: string,
    step: string = "15m",
    organisation: string
): Promise<TSMetricData> => {
    
    try {

        let headcount
        let windOrientation
        let windSpeed

        try {
            headcount = await sumHeadcount(startDateTime, endDateTime, step, organisation)
        } catch (error) {
            console.log(`Error fetching sumHeadcountByDTE time series data`)
        }

        try {
             windOrientation = await sumWindOrientation(startDateTime, endDateTime, step, organisation)
        } catch (error) {
            console.log(`Error fetching sumWindOrientation time series data`)
        }

        try {
            windSpeed = await sumWindSpeed(startDateTime, endDateTime, step, organisation)
       } catch (error) {
           console.log(`Error fetching sumWindSpeed time series data`)
       }


        return {
            headcount: headcount,
            windOrientation: windOrientation,
            windSpeed: windSpeed,
        }
    } catch (error) {
        console.error('Error fetching sumMetricData:', error);
        throw error;
    }
}