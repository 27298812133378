import { Warning48Filled } from "@fluentui/react-icons"

// Context
import { useDataContext } from "../common/contexts/DataContext";

const DataWarning = () => {
    const {warning} = useDataContext()
    return (
        <>
        {warning ? 
            <Warning48Filled />
        : null }
        </>
    )
}

export default DataWarning