// Types
import { Label } from "../../../../../@types/Settings/Label"
import { LabelValueType } from "../../../../../@types/Settings/LabelValueType"

// Utils
import { getNumberOfChutes } from "../../../../utils/func-metrics/getNumberOfChutes"

export const LabelSetEventTicketLiveBrief: Label[] = [
    {
        name: 'Number of chutes',
        valueName: 'getNumberOfChutes',
        valueType: LabelValueType.FUNC_METRIC,
        value: getNumberOfChutes
    },
]
