import React, { useEffect, useState } from "react"
import type { SpinnerProps } from "@fluentui/react-components";

// Context
import { useSettingsContext } from "../../common/contexts/SettingsContext";

// Data
import { loadingMessageData } from "../../common/data/loadingMessageData";

// Utils
import { shuffleArray } from "../../common/utils/shuffleArray";

// Components
import TLSpinner from "./TLSpinner";

interface Props {
    className?: string
    props?: SpinnerProps
}

const TLSpinnerPage:React.FC<Props> = ({className, props}) => {

    const { settings } = useSettingsContext()

    let leftFieldMessages = []
    
    if (settings && settings.leftFieldMessages) {
        leftFieldMessages = shuffleArray(settings.leftFieldMessages)[0]
    }

    const messages = settings ? loadingMessageData.concat(leftFieldMessages) : loadingMessageData
    const [currentMessage, setCurrentMessage] = useState(messages[0]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        intervalId = setInterval(() => {
          setIndex(prevIndex => {
            const newIndex = prevIndex + 1;
            if (newIndex === messages.length) {
              clearInterval(intervalId);
              return prevIndex; // Return the current index to prevent resetting it to 0
            }
            return newIndex;
          });
        }, 1250); // Change the duration each message is displayed for
    
        return () => clearInterval(intervalId);
      }, [messages.length]);
    
      useEffect(() => {
        setCurrentMessage(messages[index]);
      }, [index, messages]);

    return (
        <div className={className}>
            <div className="flex justify-center">
              <TLSpinner {...props} custom={<img src="/loading.gif" width={200} height={200} alt="Loading..." />} />
            </div>
            <p className="text-center text-xl">Loading...</p>
            <p className="text-center mt-5 text-sm">{currentMessage}</p>
        </div>
    )
}

export default TLSpinnerPage