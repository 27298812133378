export enum IndicatorColor {
    NA = '#FFFFFF', // colors.grey.white
    ZERO = '#FAFAFA', // colors.grey._98
    VERY_LOW = '#E0FFFE', // Brand/New/LightCrystal (new)
    LOW = '#BEF4F2', // colors.brand.160
    MEDIUM_LOW = '#A6EDED', // colors.brand.150
    MEDIUM = '#8FE6E8', // colors.brand.140
    MEDIUM_HIGH = '#5FD8DF', // colors.brand.120
    HIGH = '#3DD6D4', // Brand/Logo/4 (new)
    VERY_HIGH = '#1BB6D9', // Brand/Logo/1 (new)
    EXTREMELY_HIGH = '#C50F1F',// colorStatusDangerBackground3
    NA_NOT_SELECTED = '#FFFFFF', // colors.grey.white
    ZERO_NOT_SELECTED = '#FAFAFA', // colors.grey._98
    VERY_LOW_NOT_SELECTED = '#E0E0E0', // colors.grey._88
    LOW_NOT_SELECTED = '#CCCCCC', // colors.grey._80
    MEDIUM_LOW_NOT_SELECTED = '#C2C2C2', // colors.grey._76
    MEDIUM_NOT_SELECTED = '#B8B8B8', // colors.grey._72
    MEDIUM_HIGH_NOT_SELECTED = '#A8A8A8', // colors.grey._66
    HIGH_NOT_SELECTED = '#8F8F8F', // colors.grey._56
    VERY_HIGH_NOT_SELECTED = '#808080',  // colors.grey._50
    EXTREMELY_HIGH_NOT_SELECTED = '#707070', // colors.grey._44
}