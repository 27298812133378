// Types
import { DataMode } from "../../../@types/DataMode";
import { Environment } from "../../../@types/Settings/Environment";
import { IndicatorColor } from "../../../@types/Settings/IndicatorColor";
import { LabelDisplayOn } from "../../../@types/Settings/LabelDisplayOn";
import { Settings } from "../../../@types/Settings/Settings";
import { TwinEntityType } from "../../../@types/TwinEntityType";

// Data / Movico Structure Label Configs
import { LabelSetEventTruckStructureLiveBrief } from "./labelSets/live/LabelSetEventTruckStructureLiveBrief";
import { LabelSetEventTruckStructureLiveFull } from "./labelSets/live/LabelSetEventTruckStructureLiveFull";
import { LabelSetEventTruckStructureTimeSeriesBrief } from "./labelSets/timeseries/LabelSetEventTruckStructureTimeSeriesBrief";
import { LabelSetEventTruckStructureTimeSeriesFull } from "./labelSets/timeseries/LabelSetEventTruckStructureTimeSeriesFull";

// Data / Movico Floor Label Configs
import { LabelSetEventTruckAreaTimeSeriesBrief } from "./labelSets/timeseries/LabelSetEventTruckAreaTimeSeriesBrief";
import { LabelSetEventTruckAreaTimeSeriesFull } from "./labelSets/timeseries/LabelSetEventTruckAreaTimeSeriesFull";
import { LabelSetEventTruckFloorLiveBrief } from "./labelSets/live/LabelSetEventTruckAreaLiveBrief";
import { LabelSetEventTruckFloorLiveFull } from "./labelSets/live/LabelSetEventTruckAreaLiveFull";


export const movicoSitePrefData: Settings = {
    organisation: 'Movico',
    heroMetrics: [
        {
            metric: 'countEntity',
            aggregation: 'max',
        },
    ],
    liveDataInterval: '15s',
    timeZone: 'Europe/Paris',
    apolloExplorerURI: 'Twinlabs-Dev02',
    startLive: true,
    environment: {
        environment: Environment.INDOOR,
        hdrPath: '/images/hdr/indoor.hdr',
    },
    labels: [
        {
            twinEntityType: TwinEntityType.STRUCTURE,
            labelSets: [
                {
                    brief: LabelSetEventTruckStructureTimeSeriesBrief,
                    full: LabelSetEventTruckStructureTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetEventTruckStructureLiveBrief,
                    full: LabelSetEventTruckStructureLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
        {
            twinEntityType: TwinEntityType.FLOOR,
            labelSets: [
                {
                    brief: LabelSetEventTruckAreaTimeSeriesBrief,
                    full: LabelSetEventTruckAreaTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetEventTruckFloorLiveBrief,
                    full: LabelSetEventTruckFloorLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
        {
            twinEntityType: TwinEntityType.AREA,
            labelSets: [
                {
                    brief: LabelSetEventTruckAreaTimeSeriesBrief,
                    full: LabelSetEventTruckAreaTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetEventTruckFloorLiveBrief,
                    full: LabelSetEventTruckFloorLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
    ],
    entities: {
        id: 'entities',
        children: [
            {
                id: '0x4e58',
                icon: 'VehicleTruckProfile20Regular',
                header: {
                    showOverCapacityWarning: true,
                },
                children: [
                    {
                        id: '0x4e53',
                        header: {
                            showOverCapacityWarning: true,
                        },
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Moderate',
                                        value: 0,
                                    },
                                    {
                                        label: 'Crowded',
                                        value: 1,
                                    },
                                    {
                                        label: 'Overcrowded',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    {
                        id: '0x4e54',
                        header: {
                            showOverCapacityWarning: true,
                        },
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Moderate',
                                        value: 0,
                                    },
                                    {
                                        label: 'Crowded',
                                        value: 1,
                                    },
                                    {
                                        label: 'Overcrowded',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    {
                        id: '0x4e55',
                        header: {
                            showOverCapacityWarning: true,
                        },
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Moderate',
                                        value: 0,
                                    },
                                    {
                                        label: 'Crowded',
                                        value: 1,
                                    },
                                    {
                                        label: 'Overcrowded',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    {
                        id: '0x4e5a',
                        header: {
                            showOverCapacityWarning: true,
                        },
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Moderate',
                                        value: 0,
                                    },
                                    {
                                        label: 'Crowded',
                                        value: 1,
                                    },
                                    {
                                        label: 'Overcrowded',
                                        value: 2,
                                    },
                                ]
                            },
                        },
                    },
                    {
                        id: '0x4e5c',
                        header: {
                            showOverCapacityWarning: true,
                        },
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Moderate',
                                        value: 0,
                                    },
                                    {
                                        label: 'Crowded',
                                        value: 1,
                                    },
                                    {
                                        label: 'Overcrowded',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    }
                ]
            },
        ]
    },
    analytics: {
        id: 'analytics',
        introPrompts: [
            {
                title: "Which floor or area is susceptible to overcrowding?",
                prompt: "Which floor or area is susceptible to overcrowding?",
            },
            {
                title: "At what time in the day is overcrowding likely to occur?",
                prompt: "At what time in the day is overcrowding likely to occur?",
            },
            {
                title: "Tell me the maximum windspeed for each day over the past 7 days",
                prompt: "Tell me the maximum windspeed for each day over past 7 days",
            },
        ]
    },
    timeline: {
        id: 'timeline',
        visible: true,
        open: true,
        order: 1,
        interval: '15m',
        hourRange: {
            startTime: '07:00',
            finishTime: '19:00',
        }
    },
    calendar: {
        id: 'calendar',
        visible: true,
        open: true,
        icon: '',
        order: 2,
        hourRange: {
            startTime: '07:00',
            finishTime: '19:00',
        }
    },
    rangeMapping: [
        {
            color: IndicatorColor.ZERO,
            op: `value === 0`,
        },
        {
            color: IndicatorColor.VERY_LOW,
            op: `value >= 1 && value <= 10`,
        },
        {
            color: IndicatorColor.LOW,
            op: `value >= 11 && value <= 20`,
        },
        {
            color: IndicatorColor.MEDIUM_LOW,
            op: `value >= 21 && value <= 30`,
        },
        {
            color: IndicatorColor.MEDIUM,
            op: `value >= 31 && value <= 40`,
        },
        {
            color: IndicatorColor.MEDIUM_HIGH,
            op: `value >= 41 && value <= 50`,
        },
        {
            color: IndicatorColor.HIGH,
            op: `value >= 51 && value <= 75`,
        },
        {
            color: IndicatorColor.VERY_HIGH,
            op: `value >= 76 && value <= 100`,
        },
        {
            color: IndicatorColor.EXTREMELY_HIGH,
            op: `value > 100`,
        }
    ],
    leftFieldMessages: [
        "Fixing a flat tyre",
        "Checking the bike suspension",
        "Shaving my legs to improve aerodynamics",
        `Using The "N+1" Rule to justify my latest bike purchase`,
    ],
    pollTwin: false,
}