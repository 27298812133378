import React, { useMemo } from "react";
import { DoubleSide, Vector2 } from "three";

// Types
import { Entity } from "./../../../@types/Entity";
import { DataMode } from "../../../@types/DataMode"

// Context
import { useDataContext } from "../../../common/contexts/DataContext";
import { useFilterContext } from "../../../common/contexts/FilterContext";
import { useSettingsContext } from "../../../common/contexts/SettingsContext";

// Utils
import { getDataValue } from "../../../common/utils/func-metrics/getDataValue"
import { getIndicator } from "../../../common/utils/getIndicator";
import { OutlineSolid } from "../outlines/OutlineSolid";

interface Props {
	entity: Entity;
    geometry: any;
    colorMode: 'greys' | 'colors';
    visible: boolean;
    showOutline: boolean;
}

const Capacity = ({ entity, geometry, colorMode, visible, showOutline }: Props) => {

	// Access data-related functions and settings from Data Context.
	const { live } = useFilterContext();
    const { data } = useDataContext();
    const { settings } = useSettingsContext();

    const id = live ? entity.id : entity.bID
    const capacity = entity.capacity

    const countEntity = data.processed?.countEntity ?  data.processed?.countEntity : []
    const dataValue = getDataValue(
        live ? DataMode.LIVE : DataMode.TIME_SERIES,
        countEntity,
        id)

    const headcount = dataValue ? dataValue.value : 0

    const color = useMemo(() => getIndicator(
        headcount, 
        capacity ? capacity : 0, 
        settings?.rangeMapping, 
        colorMode === 'colors' ? true : false).color,
    [headcount, capacity, settings, colorMode])
    
    return(
        <group visible={visible}>
            <OutlineSolid geometry={geometry} lineWidth={0.03} color={color} visible={showOutline}/>
            <mesh
                userData={entity}
                geometry={geometry}
            >
                <meshStandardMaterial
                    color={ color }
                    forceSinglePass
                    transparent
                    opacity={0.22}
                    roughness={0.9}
                    metalness={0.3}
                    side={DoubleSide}
                    normalScale={new Vector2(0.25, 0.25)}
                    depthWrite={false}
                />
            </mesh>
        </group>
    )
}

export { Capacity }