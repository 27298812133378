/*
 * findEntityByBusinessId
 *
*/
export const findEntityByBusinessId = (array: any[], businessId: string) => {

    let foundEntity = null;

    if (array) {
        array.some(obj => {
            if (obj.bID === businessId) {
                foundEntity = obj;
                return true; // Break out of the loop if the object is found
            }
            if (obj.children && Array.isArray(obj.children)) {
                foundEntity = findEntityByBusinessId(obj.children, businessId); // Recursively search children arrays
                return foundEntity !== null; // Break out of the loop if object is found in children
            }
            return null
        });
    }

    return foundEntity;
}
