/*
 * getBatteryStatus
 * Function Metric
 *
*/
import { DataValue } from "../../../@types/Data/DataValue"

export const getBatteryStatus = (data: DataValue[], entityId: string): string => {
    // TODO: Use entityID to work out cumulative count. Need data.
    if (entityId) {
        return `100%`
    }
    return `100%`
}