export const getInitials = (value: string) => {
    // Split the string into words
    const words = value.split(' ');
    let initials = '';
    // Loop through each word to get the first letter and add it to the initials string
    words.forEach(word => {
        const firstLetter = word.charAt(0).toUpperCase();
        initials += firstLetter;
    });
    return initials;
}
