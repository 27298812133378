/*
 * calcUtilisation
 * Utilisation Hero Metric
 *
*/
// Types
import { DataMode } from "../../../@types/DataMode"
import { DataValue } from "../../../@types/Data/DataValue"

// Utils
import { getDataValue } from "./getDataValue"

export const calcUtilisation = (dataMode: DataMode, data: DataValue[], id: string, capacity: number): string => {
     if (data && data.length > 0) {
        const headcount = getDataValue(dataMode, data, id)?.value
        return `${headcount > 0 && capacity > 0 ? Math.ceil((headcount / capacity) * 100) : 0}%`
     } else {
        return 'N/A'
     }
}