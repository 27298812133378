// Types
import { Label } from "../../../../../@types/Settings/Label"
import { LabelValueType } from "../../../../../@types/Settings/LabelValueType"

// Utils
import { getHeadcount } from "../../../../utils/func-metrics/getHeadcount"
import { getUsage } from "../../../../utils/func-metrics/getUsage"

export const LabelSetGymAssetTimeSeriesFull: Label[] = [
    {
        name: 'Short Name',
        valueType: LabelValueType.ENTITY_PROPERTY,
        value: 'shortName'
    },
    {
        name: 'Category',
        valueType: LabelValueType.ENTITY_PROPERTY,
        value: 'parentName'
    },
    {
        name: 'Brand',
        valueType: LabelValueType.ENTITY_PROPERTY,
        value: 'maker'
    },
    {
        name: 'Average Utilisation',
        valueName: 'calcAvgUtilisation',
        valueType: LabelValueType.FUNC_METRIC,
        value: getUsage
    },
    {
        name: 'Capacity',
        valueType: LabelValueType.ENTITY_PROPERTY,
        value: 'capacity'
    },
    {
        name: 'Average Headcount',
        valueName: 'getAvgHeadcount',
        valueType: LabelValueType.FUNC_METRIC,
        value: getHeadcount
    },
]