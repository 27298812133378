import React, { Dispatch, SetStateAction } from "react";
import {
  Tab,
  TabList,
} from "@fluentui/react-components";

// Types
import { TabOption } from "../../@types/Tab";
import type { TabListProps } from "@fluentui/react-components";

interface Props {
    className?: string
    props?: TabListProps
    selectedValue?: string
    options: TabOption[]
    setValue?: Dispatch<SetStateAction<any>>
}

const TLTabList: React.FC<Props> = ({ className, props, selectedValue, options, setValue }) => {
    return (
        <div className={className}>
            <TabList
                defaultSelectedValue={selectedValue}
            >
                {options.map((opt, i) => {
                    return (
                        <Tab icon={opt.activeIcon ? opt.activeIcon : null} key={i} value={opt.value} onClick={setValue ? () => setValue(opt.value) : () => { }}>{opt.text}</Tab>
                    )
                })}
            </TabList>
        </div>
    )
}

export default TLTabList