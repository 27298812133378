// Types
import { Label } from "../../../../../@types/Settings/Label"
import { LabelValueType } from "../../../../../@types/Settings/LabelValueType"

// Utils
import { getCumulativeCount } from "../../../../utils/func-metrics/getCumulativeCount"
import { getNumberOfChutes } from "../../../../utils/func-metrics/getNumberOfChutes"
import { getPercentageOfSold } from "../../../../utils/func-metrics/getPercentageOfSold"
import { getTransactionRate } from "../../../../utils/func-metrics/getTransactionRate"

export const LabelSetEventAreaTimeSeriesFull: Label[] = [
    {
        name: 'Number of chutes',
        valueName: 'getNumberOfChutes',
        valueType: LabelValueType.FUNC_METRIC,
        value: getNumberOfChutes
    },
    {
        name: 'Transaction Rate',
        valueName: 'getTransactionRate',
        valueType: LabelValueType.FUNC_METRIC,
        value: getTransactionRate
    },
    {
        name: 'Cumulative Count',
        valueName: 'getCumulativeCount',
        valueType: LabelValueType.FUNC_METRIC,
        value: getCumulativeCount,
    },
    {
        name: '% of sold',
        valueName: 'getPercentageOfSold',
        valueType: LabelValueType.FUNC_METRIC,
        value: getPercentageOfSold,
    },
]