/*
 * getDataValue
 *
*/
// Types
import { DataValue } from "../../../@types/Data/DataValue"
import { DataMode } from "../../../@types/DataMode"

// Utils
import { findObjectByPropertyValue } from "../findObjectByPropertyValue"

export const getDataValue = (dataMode: DataMode, data: DataValue[], id: string): DataValue => {
    return findObjectByPropertyValue(data, dataMode === DataMode.LIVE ? 'entityId' : 'bID', id)
}