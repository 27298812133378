// Types
import { Label } from "../../../../../@types/Settings/Label"
import { LabelValueType } from "../../../../../@types/Settings/LabelValueType"

// Utils
import { calcMaxUtilisation } from "../../../../utils/func-metrics/calcMaxUtilisation"

export const LabelSetEventTruckStructureTimeSeriesBrief: Label[] = [
    {
        name: 'Max Utilisation',
        valueName: 'calcMaxUtilisation',
        valueType: LabelValueType.FUNC_METRIC,
        value: calcMaxUtilisation
    },
]