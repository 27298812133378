/*
 * getMaxWindSpeed
 * 
*/

// Types
import { maxWindSpeed } from "../../api/timeseries/maxWindSpeed"

export const getMaxWindSpeed = async (startDateTime: string, endDateTime: string, step: string = "15m", organisation: string): Promise<string> => {
    
    let value = 'N/A'

    if (startDateTime && endDateTime && step) {

        const maxWindSpeeds = await maxWindSpeed(startDateTime, endDateTime, step, organisation)

        if (maxWindSpeeds && maxWindSpeeds.length > 0) {
            value = `${Math.round(maxWindSpeeds[0].values[0][1])}m/s`
        } else {
            value = 'No value (TS)'
        }

    } else {
        value = 'N/A'
    }

    return value
}