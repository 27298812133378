// Types
import { Label } from "../../../../../@types/Settings/Label"
import { LabelValueType } from "../../../../../@types/Settings/LabelValueType"

// Utils
import { getBatteryStatus } from "../../../../utils/func-metrics/getBatteryStatus"
import { getCumulativeCount } from "../../../../utils/func-metrics/getCumulativeCount"
import { getPercentageOfSold } from "../../../../utils/func-metrics/getPercentageOfSold"
import { getTransactionRate } from "../../../../utils/func-metrics/getTransactionRate"

export const LabelSetEventChuteTimeSeriesFull: Label[] = [
    {
        name: 'Battery Status',
        valueName: 'getBatteryStatus',
        valueType: LabelValueType.FUNC_METRIC,
        value: getBatteryStatus
    },
    {
        name: 'Transaction Rate',
        valueName: 'getTransactionRate',
        valueType: LabelValueType.FUNC_METRIC,
        value: getTransactionRate
    },
    {
        name: 'Cumulative Count',
        valueName: 'getCumulativeCount',
        valueType: LabelValueType.FUNC_METRIC,
        value: getCumulativeCount,
    },
    {
        name: '% of sold',
        valueName: 'getPercentageOfSold',
        valueType: LabelValueType.FUNC_METRIC,
        value: getPercentageOfSold,
    },
]