import axios from 'axios';
import Config from '../../Config';
import { RawLoginData, LoginResult } from '../../../@types/LoginResult';

export const login = async (email: string, password: string): Promise<LoginResult> => {

    if (Config.authEndpoint) {
        
        // Encode the credentials in base64
        const token = btoa(`${email}:${password}`);
    
        // Make the request with the Authorization header
        const result = await axios.get<RawLoginData>(Config.authEndpoint, {
          headers: {
            'Authorization': `Basic ${token}`
          }
        }).then(response => {
          return {
            success: true,
            data: response.data
          } as LoginResult
        })
        .catch(error => {
          return {
            statusCode: error.code,
            success: false,
            data: { error: error }
        } as LoginResult
        });

        return result

    } else {
      return  { 
        success: false,
        data: {
          error: 'Auth endpoint is not defined'
        }
        }
    }

}