import { ReactNode, useMemo, useState } from "react";
import { Box3 } from "three";

import { Label3D } from "../../Label3D";
import { Hitbox } from "../../Hitbox";
import { useFilterContext } from "../../../../common/contexts/FilterContext";
import { useEventContext } from "../../../../common/contexts/EventContext";
import { TwinEntityType } from "../../../../@types/TwinEntityType";
import { LabelMode } from "../../../../@types/LabelMode";
import { getAreaOutlineShape } from "../../utils/getAreaOutline";
import { Entity } from "../../../../@types/Entity";
import { TwinEntity } from "../../../../generated/devGraphql";
import { stringToTwinEntityType } from "../../../../common/utils/stringToTwinEntityType";
import { determineVisibility, getMenuHovered, getSelectionCriteria } from "../../utils/filterUtils";
import { NotTracked } from "../../behaviours/NotTracked";
import { convertCoordLocalPoint } from "../../utils/coorUtils";
import { SelectionOutlines } from "../../outlines/SelectionOutline";
import { Capacity } from "../../behaviours/Capacity";

/**
	* Area Component
	* For all areas  
*/

interface Props {
	entity: Entity; // The backend twin data graphQL configuration needed to generate 3D components.
    mapDiv: HTMLDivElement; //Reference to the Mapbox map container element from React-Three-Map for event triggering css changes to the map cursor style.
	lineage: TwinEntity[] // the acenstry of this entity, oldest first
	depth: number;
	children: ReactNode;
}


// TODO should selection be different for different types of area?
export const Area = ({ entity, mapDiv, depth, lineage, children }: Props) => {

	const { filter } = useFilterContext();
	const { currentHoveredEntity } = useEventContext();
	const [hover, setHover] = useState(false)
	const [pressed, setPressed] = useState(false)

	const parent = lineage[lineage.length - 1]
	const parentType = stringToTwinEntityType(parent.type.name)
	const isTopLevelArea = parentType === TwinEntityType.SITE

	const { filtersExist, 
		areaIsSelected, 
		parentIsSelected,
		siblingIsSelected 
	} = getSelectionCriteria(entity, parent, filter)
	
	const fullLineageIncludingThisEntity = [...lineage, entity]
	const menuHovered = getMenuHovered(fullLineageIncludingThisEntity, currentHoveredEntity)

	const showSolidOutline = areaIsSelected  || pressed;
	const showDashedOutline = (hover || menuHovered) && !areaIsSelected
	const showBehaviourBoxOutline = !(showSolidOutline || showDashedOutline)

	const { centre, areaBlock } = useMemo(() => {
			
		if (entity.boundaries === undefined || entity.boundaries == null) {
			throw new Error(`Area entity with id ${entity.id} has no boundaries. We cannot render it`)
		}

		return getAreaOutlineShape(entity.boundaries.polygons, depth)}, [entity, depth]
	);

	const labelPosition = convertCoordLocalPoint(centre, 1.5)
	// this bounding box assertion is safe because we ensure it is present in getAreaOutLineShape
	const scaledBoundingBox = new Box3(areaBlock.boundingBox!.min.multiplyScalar(0.01), areaBlock.boundingBox!.max.multiplyScalar(0.01)) 
	
	const behavioursVisible = determineVisibility(entity, { 
		filtersExist, 
		areaIsSelected, 
		parentIsSelected, 
		siblingIsSelected, 
		isTopLevelArea })
	const colorMode = filtersExist && !areaIsSelected && siblingIsSelected ? 'greys' : 'colors'
	
	return (
			<group position={[ 0, (depth / 4000) , 0]}>
				<Hitbox 
					entity={entity}
					geometry={ areaBlock } 
					setHover={setHover} 
					setPressed={setPressed}
					mapDiv={mapDiv} 
					lineage={lineage}
				/>
				<SelectionOutlines geometry={areaBlock} showDashedOutline={showDashedOutline} showSolidOutline={showSolidOutline}/>
				{ entity.tracked ? 
				<Capacity 
					entity={entity} 
					geometry={areaBlock} 
					colorMode={colorMode}
					visible={behavioursVisible}
					showOutline={showBehaviourBoxOutline}
				/> 
				:
				<NotTracked 
					geometry={areaBlock} 
					visible={behavioursVisible} 
					showOutline={showBehaviourBoxOutline}
				/>
				}
				{ (hover || menuHovered || areaIsSelected) &&
				<object3D position={labelPosition}>
					<Label3D 
						entity={entity} 
						entityType={TwinEntityType.ZONE} 
						labelMode={areaIsSelected ? LabelMode.FULL: LabelMode.BRIEF}
						boundingBox={scaledBoundingBox}  
					/>
				</object3D>	
				}
				{children}

		</group>
	);
}



