import { useEffect, useState } from "react";
import { Asset, TempAsset } from "./Asset";
import { useSecureModelURL } from "../../utils/modelFilePaths";
import { Entity } from "../../../../@types/Entity";
import { TwinEntity } from "../../../../generated/devGraphql";

interface Props {
    entity: Entity;
    mapDiv: HTMLDivElement;
    loadingList: Map<string, boolean>;
    lineage: TwinEntity[]
}

/**
 * 
 * This component handles the case where a model file is not found, loading a placeholder instead if the model is 
 * not available
 * */ 

export const HandleAsset = ({ entity, mapDiv, loadingList, lineage }: Props) => {

    const [hasModel, setHasModel] = useState(false)


    // This is safe because it's been checked in TwinEnity
    const modelFileName = entity.modelFile!

    const modelAlreadyLoaded = loadingList.get(modelFileName)

    const modelURL = useSecureModelURL(modelFileName)
    
    useEffect(() => {

        const checkModelURL = async () => {

            try {

                const response = await fetch(modelURL);

                if (response.ok) {
                    setHasModel(response.ok)
                } else {
                    loadingList.delete(modelFileName) // it doesn't need to be loaded
                }

            } catch (error) {

            console.error('Error fetching data:', error);
            
            }
        }

        if (!modelAlreadyLoaded) checkModelURL()

    },[modelURL, modelFileName, loadingList, modelAlreadyLoaded])

    return(

        <group >
            
            {hasModel || modelAlreadyLoaded ?
                // loads models
                <Asset 
                    entity={entity} 
                    modelFileName={modelFileName}
                    mapDiv={mapDiv} 
                    loadingList={loadingList}
                    lineage={lineage}
                    />
            : 
                <TempAsset entity={entity} mapDiv={mapDiv} lineage={lineage} />
            }
        
        </group>
    )
}
