import { Component, ErrorInfo, PropsWithChildren } from "react"

interface State { 
    hasError: boolean,
    errorMessage: string,
    }

interface Props extends PropsWithChildren {
    onError: () => void
}


export class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = { 
        hasError: false, 
        errorMessage: ''
    };
    }
  
    static getDerivedStateFromError(error: Error) {
      // Update state so the next render will show the fallback UI.
      return { 
        hasError: true,
        errorMessage: error.message 
    };
    }
  
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      // You can also log the error to an error reporting service
      console.error('Error occurred within the ErrorBoundary', error, errorInfo);
      this.props.onError()
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <FallbackUI errorMessage={this.state.errorMessage} />;
      }
  
      return this.props.children; 
    }
  }
  
  function FallbackUI({errorMessage}: { errorMessage: string }) {
    return <p style={{
        position: 'absolute', 
        left: 0,
        right: 0, 
        marginTop: '300px',
        marginBottom: 'auto',
        marginLeft: 'auto', 
        marginRight: 'auto', 
        width: '300px' /* Need a specific value to work */
      }}>
        <p>Something went wrong rendering the Twin in 3D</p>
        <br/>
        <p>Error message: </p>
        <br/>
        <p> {errorMessage}</p>
        </p>;
  }


