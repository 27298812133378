import { BufferGeometry, EdgesGeometry } from "three";
import { LineSegmentsGeometry } from "three/examples/jsm/lines/LineSegmentsGeometry";


export function makeLineGeometry(geometry: BufferGeometry) {
    const edgesGeometry = new EdgesGeometry( geometry );

        const lineGeometry = new LineSegmentsGeometry().fromEdgesGeometry(edgesGeometry);

        return lineGeometry
} 