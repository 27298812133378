/*
 * evaluateStringAsFunction
 * This function is primarily used in conjunction with the function getIndicator 
 * It allows us to take a string operation defined in Settings and resolve to an Indicator Color
 * 
*/
export const evaluateStringAsFunction = (input: string) =>  {
    const functionString = `(value) => ${input}`;
    // Disabling the eval can be harmful no-eval
    // We need eval here to evaluate the opertions for indicator colours. See rangeMapping object example here: src/common/data/mock/newportSitePrefData.ts
    // eslint-disable-next-line
    const func = eval(functionString);
    return func;
}
