/*
 * TwinLab.ai Figma Variables for Fluent 2 
 * 
*/
export const figmaVariables = {
    colors: {
        grey: {
            black:"#000000",
            2:"#050505",
            4:"#0A0A0A",
            6:"#0F0F0F",
            8:"#141414",
            10:"#1A1A1A",
            12:"#1F1F1F",
            14:"#242424",
            16:"#292929",
            18:"#2E2E2E",
            20:"#333333",
            22:"#383838",
            24:"#3D3D3D",
            26:"#424242",
            28:"#474747",
            30:"#4D4D4D",
            32:"#525252",
            34:"#575757",
            36:"#5C5C5C",
            38:"#616161",
            40:"#666666",
            42:"#6B6B6B",
            44:"#707070",
            46:"#757575",
            48:"#7A7A7A",
            50:"#808080",
            52:"#858585",
            54:"#8A8A8A",
            56:"#8F8F8F",
            58:"#949494",
            60:"#999999",
            62:"#9E9E9E",
            64:"#A3A3A3",
            66:"#A8A8A8",
            68:"#ADADAD",
            70:"#B3B3B3",
            72:"#B8B8B8",
            74:"#BDBDBD",
            76:"#C2C2C2",
            78:"#C7C7C7",
            80:"#CCCCCC",
            82:"#D1D1D1",
            84:"#D6D6D6",
            86:"#DBDBDB",
            88:"#E0E0E0",
            90:"#E6E6E6",
            92:"#EBEBEB",
            94:"#F0F0F0",
            96:"#F5F5F5",
            98:"#FAFAFA",
            white: "#FFFFFF",
            white40: "rgba(255, 255, 255, 0.4)",
            white60: "rgba(255, 255, 255, 0.6)",
        },
        brand: {
            10:"#001615",
            20:"#002E2F",
            30:"#004549",
            40:"#005D63",
            50:"#00747E",
            60:"#008C98",
            70:"#00A3B2",
            80:"#00BBCC",
            90:"#18C2D1",
            100:"#30C9D6",
            110:"#47D0DA",
            120:"#5FD8DF",
            130:"#77DFE4",
            140:"#8FE6E8",
            150:"#A6EDED",
            160:"#BEF4F2",
        },
        graph: {
            10:"#7A4DFE",
            20:"#8368FE",
            30:"#7C82FB",
            40:"#7599F3",
            50:"#66AEEC",
            60:"#51C4E4",
        },
    },
    cornerRadius: {
        none: 0,
        20: 2,
        40: 4,
        60: 6,
        80: 8,
        120: 12,
        circular: 99
    },
    size: {
        none: 0,
        20: 2,
        40: 4,
        60: 6,
        80: 8,
        120: 12,
        160: 12,
        200: 12,
        240: 12,
        280: 12,
        320: 12,
        360: 12,
        400: 12,
        480: 12,
        520: 12,
        560: 12,
    },
    strokeWidth: {
        none: 0,
        5: 0.5,
        10: 1,
        15: 1.5,
        20: 2,
        30: 3,
        40: 4,
        60: 6,
    },
}