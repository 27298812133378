/* 
* DataContext
* 
*/
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { FetchResult } from "@apollo/client";
import { useApolloClient } from "@apollo/client";

// Types
import { Metric } from "../../generated/devGraphql";
import { Entity } from "../../@types/Entity";
import { Filter } from "../../@types/Filter"
import { Data } from "../../@types/Data/Data";
import { TSMetricData } from "../../@types/Data/TSMetricData";
import { SortedDataValue } from "../../@types/Data/SortedDataValue";

// Context
import { useFilterContext } from "./FilterContext";
import { useSettingsContext } from "./SettingsContext"
import { useTwinContext } from "./TwinContext";

// Data
import { QUERY_METRIC_VALUE } from "../api/live/gql/queryMetricValue"; // Returns Live Metric (Sensor) Data (e.g. Headcount)
import { avgMetricData } from "../api/timeseries/avgMetricData"; // Returns AVG Time Series Metric (Sensor) Data (e.g. Avg Headcount for an Entity)
import { maxMetricData } from "../api/timeseries/maxMetricData" // Returns MAX Time Series Metric (Sensor) Data (e.g. Max Headcount for an Entity)
import { sumMetricData } from "../api/timeseries/sumMetricData"; // Returns RAW Time Series Metric (Sensor) Data (e.g. Sum Headcount data for an Entity)

// Utils
import { addDataInterval } from "../utils/addDataInterval";
import { calculateUsage } from "../utils/calculateUsage";
import Config from "../Config";
import { findEntityByBusinessId } from "../utils/findEntityByBusinessId";
import { getDifferenceBetweenDates } from "../utils/getDifferenceBetweenDates";
import { mapLiveSubscriptionData } from "../utils/mappers/mapLiveSubscriptionData";
import { mapTimeSeriesData } from "../utils/mappers/mapTimeSeriesData";
import { sortDataValuesByType } from "../utils/sortDataByType";
import { convertDateTimeISOToUTC } from "../utils/convertDateTimeToUTC";

interface DataContextValue {
    fetchLiveData: boolean
    fetchTimeSeriesData: boolean
    filter: Filter[]
    data: Data // Can hold Live or Time Series data depending on the app being in a live state
    liveData: FetchResult<any> | null // Live data populated by subscription/web socket.
    warning: boolean // A flag to allow us to display warnings about the data integrity
    setData: (data: any[]) => void
}

const initialState: DataContextValue = {
    fetchLiveData: true,
    fetchTimeSeriesData: false,
    filter: [],
    data: { raw: [], processed: null },
    liveData: null,
    warning: false,
    setData: () => { },
}

export const DataContext = createContext<DataContextValue>(initialState)

export const useDataContext = (): DataContextValue => {
    return useContext(DataContext);
};
interface ContextProviderProps {
    children: React.ReactNode;
}

export const DataContextProvider: React.FC<ContextProviderProps> = (props) => {

    const apolloClient = useApolloClient()
    const { settings, metrics } = useSettingsContext()
    const { twin } = useTwinContext()

    const { filter, live, startDateTime, finishDateTime } = useFilterContext()
    const [dataState, setDataState] = useState<DataContextValue>(initialState);
    const [liveDataLastParsedAt, setLiveDataLastParsedAt] = useState<Date | null>(null)

    const prevFilterContextRef = useRef({filter});

    const setData = useCallback((data: Data) => {
        setDataState((prevState) => ({ ...prevState, data }));
    }, [])

    const setLiveData = useCallback((liveData: FetchResult<any>) => {
        setDataState((prevState) => ({ ...prevState, liveData }));
    }, [])

    const setWarning = useCallback((warning: boolean) => {
        setDataState((prevState) => ({ ...prevState, warning }));
    }, [])

    /*
    * Parse the live metric data and map to ensure it's in the right shape 
    * to store in the data context
    *
    */
    const parseLiveData = useCallback((liveData: FetchResult<any>, metrics: Metric[]): Data => {

        const mappedData = mapLiveSubscriptionData(liveData.data.queryMetricValue, metrics)

        // Used to help catch live data issues
        const showWarning = Config.metricDataWarning ? Config.metricDataWarning : false
        if (showWarning) {
            setWarning(mappedData.warning)
        }
        
        let processedData = sortDataValuesByType(mappedData.data)
        console.log(`Live Processed Data`, processedData)
        return { raw: liveData.data.queryMeasure, processed: processedData}
    }, [
        setWarning,
    ])

    /* 
     * Manually add usage metric to time series processedData
     * WARNING/REGRET: Transversing the twin multiple times to get the capacity figure for each entity is quite expensive.
     * I'm also not keen on consuming the TwinContext within the DataContext, as I think this will lead to uncessary re-rendering esp. with Howler.
     * This should be a very temporary measure only.
     *  
    */
    const calcUsageMetrics = useCallback((processedData: SortedDataValue, key: string) => {
        let usageMetrics: { bID: string | undefined; bIDPath: string | undefined; timestamp: string; value: number; }[] = []
        if (processedData && processedData[key] && processedData[key].length > 0) {
            if (twin?.model) {
                processedData[key].forEach(ce => {
                    if (ce.bID) {

                        let entity: Entity | null = findEntityByBusinessId([twin.model], ce.bID)

                        if (entity) {
                        let capacity = entity['capacity']

                        usageMetrics.push({
                            bID: ce.bID,
                            bIDPath: ce.bIDPath,
                            timestamp: ce.timestamp,
                            value: ce.value > 0 ? calculateUsage(ce.value, capacity) : 0,
                        })
                        }
                    }
                })
            }
        }
        return usageMetrics
    }, [twin?.model])

    /*
    * Parse the time series metric data and map to ensure it's in the right shape 
    * to store in the data context
    *
    */
    const parseTimeSeriesData = useCallback((timeSeriesData: TSMetricData, metrics: Metric[]): Data => {            
        
        // Extract value and set to DataValue.value
        const mappedData = mapTimeSeriesData(timeSeriesData, metrics)

        // Sort data into 'buckets' of metric data
        let processedData = sortDataValuesByType(mappedData)

        // Add usage metric to time series processedData
        let usageMetrics = calcUsageMetrics(processedData, 'countEntity')
        
        /* @ts-ignore */
        processedData['usage'] = usageMetrics
        
        return { raw: timeSeriesData, processed: processedData }
    }, [
        calcUsageMetrics,
    ])

    /*
    * Fetch Time Series Data and utilise filters from filterContext
    *
    */
    const fetchTimeSeriesData = useCallback(async (filter: Filter[]): Promise<TSMetricData> => {
        
        // Call Time series endpoint and utilise the filter[] prop within filterContext as params
        if (!startDateTime) {
            throw new Error("fetchTimeSeriesData is missing value for startDateTime")
        }

        if (!finishDateTime) {
            throw new Error("fetchTimeSeriesData is missing value for finishDateTime")
        }

        const startDateTimeQuery = convertDateTimeISOToUTC(startDateTime)
        const finishDateTimeQuery = convertDateTimeISOToUTC(finishDateTime)

        let timeSeriesData

        if (startDateTimeQuery && finishDateTimeQuery && settings?.organisation) {

            let step = getDifferenceBetweenDates(startDateTimeQuery, finishDateTimeQuery, 'seconds')

            // TODO: Rob has mentioned that a future enhancement will be to have multiple hero metrics. These will likely need to be interchangable/selectable by the end-user
            if (settings?.heroMetrics[0].aggregation === 'avg') {
                timeSeriesData = await avgMetricData(startDateTimeQuery, finishDateTimeQuery, `${step}s`, settings.organisation)
            } else if (settings?.heroMetrics[0].aggregation === 'max') {
                timeSeriesData = await maxMetricData(startDateTime.split('.')[0], finishDateTime.split('.')[0], `1d`, settings.organisation)
            } else {
                // Default to a sum time series query
                timeSeriesData = await sumMetricData(startDateTime.split('.')[0], finishDateTime.split('.')[0], `1d`, settings.organisation)
            }

        } else {
            throw new Error("fetchTimeSeriesData requires organisation within Twin Settings");
        }

        return timeSeriesData
        
    }, [
        startDateTime,
        finishDateTime,
        settings?.heroMetrics,
        settings?.organisation
    ])

    /*
    * For Live Mode
    * 
    */
    useEffect(() => {
        const getLiveData = async () => {
            let data
            if (live) {
                if (dataState.liveData) {

                    const currentDateTime = new Date()
                    const liveDataInterval = settings?.liveDataInterval ? settings?.liveDataInterval : '15s'
                    const parseNextAt = liveDataLastParsedAt ? addDataInterval(liveDataLastParsedAt, liveDataInterval) : null
                    const filterChanged = prevFilterContextRef.current.filter !== filter

                    // Uncomment to test live buffer frequency is working as expect
                    // console.log(`- - - - - - - - - - - - - - - - - - - - - - - - - -`)
                    // console.log(`currentDateTime: ${currentDateTime}`)
                    // console.log(`liveDataInterval: ${liveDataInterval}`)
                    // console.log(`parseNextAt: ${parseNextAt}`)
                    // console.log(`filterChanged: ${filterChanged}`)
                    
                    if (filterChanged || !parseNextAt || currentDateTime > parseNextAt) {
                        data = parseLiveData(dataState.liveData, metrics)
                        setData(data)
                        setLiveDataLastParsedAt(new Date())
                    }
                    // TODO: Apply any filters within filterContext to the live dataset i.e. entityID
                }
            }

            if (data) setData(data)
        }
        
        // Ensure the metrics data has been fetched
        if (metrics && metrics.length > 0) {
            getLiveData()
        }

        prevFilterContextRef.current.filter = filter
        
    }, [
        live,
        filter,
        dataState.liveData,
        setData,
        parseLiveData,
        parseTimeSeriesData,
        metrics,
        liveDataLastParsedAt,
        settings?.liveDataInterval
    ])

    /*
    * For Time Series (offline) Mode
    * 
    */
    useEffect(() => {

        const getTimeSeriesData = async () => {
            let data
            if (!live) {
                // TODO: Apply any filters within filterContext to the time series dataset i.e. entityID
                const timeSeriesdata = await fetchTimeSeriesData(filter)
                data = parseTimeSeriesData(timeSeriesdata, metrics)
                console.log(`Time Series Data`, data)
                setData(data)
            }
        }
        
        // Ensure the metrics data has been fetched
        if (metrics && metrics.length > 0) {
            getTimeSeriesData()
        }
        
    }, [
        live,
        filter,
        fetchTimeSeriesData,
        parseTimeSeriesData,
        setData,
        metrics,
        startDateTime,
        finishDateTime
    ])

    /*
    * LIVE Subscription to GraphQL Headcount Measure Data
    * This continually runs in the background regardless of the Live/Time series state
    * 
    */
    useEffect(() => {
        const subscribeToLiveData = () => {
            apolloClient.subscribe({ query: QUERY_METRIC_VALUE }).subscribe({
                next(liveData) {
                    setLiveData(liveData)
                },
                    error(error) {
                    console.error('Subscription error:', error);
                },
            });
        }
        subscribeToLiveData()
    }, [apolloClient, setLiveData])

    const contextValue = useMemo(() => ({
        ...dataState,
    }), [dataState]);

    return (
        <DataContext.Provider
            value={contextValue}
        >
            {props.children}
        </DataContext.Provider>
    )
}