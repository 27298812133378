/*
 * sortDataValuesByType
 * 
*/
import { DataValue } from "../../@types/Data/DataValue";
import { SortedDataValue } from "../../@types/Data/SortedDataValue";

export const sortDataValuesByType = (dataValues: DataValue[]): SortedDataValue => {
    const sortedObject: SortedDataValue = {};
    dataValues.forEach(dv => {
        if (!sortedObject[dv.metric.name]) {
            sortedObject[dv.metric.name] = [];
        }
        sortedObject[dv.metric.name].push(dv);
    });
    return sortedObject;
}
