/*
 * Fisher-Yates shuffle algorithm
 * Takes in an array, shuffles the items and returns the array back
 * 
*/
export const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}