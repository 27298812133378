/* 
* AppContext
* 
*/
import React, { createContext, useCallback, useContext, useMemo, useState } from "react"
interface AppContextValue {
    showSettings: boolean
    setShowSettings: (showSettings: boolean) => void
}

const initialState: AppContextValue = {
    showSettings: false,
    setShowSettings: () => { },
}

// Creates context named AppContext with the initial state.
export const AppContext = createContext<AppContextValue>(initialState)

// Defines a custom hook useAppContext that uses the useContext hook to access the AppContext.
export const useAppContext = (): AppContextValue => {
    return useContext(AppContext);
};

interface AppPromptContextProviderProps {
    children: React.ReactNode;
}

export const AppContextProvider: React.FC<AppPromptContextProviderProps> = (props) => {

    const [appState, setAppState] = useState<AppContextValue>(initialState);

    const setShowSettings = useCallback((showSettings: boolean) => {
        setAppState((prevAppState) => ({ ...prevAppState, showSettings }));
    }, [])

    // Memoise the context value to prevent unnecessary re-renders
    const contextValue = useMemo(() => {
        return {
            ...appState,
            setShowSettings,
        }
      }, [
        appState,
        setShowSettings,
        ]);

    return (
        <AppContext.Provider
            value={contextValue}
        >
            {props.children}
        </AppContext.Provider>
    )
}
