import Config from "../Config"

/*
 * resolveEndpoints
 * 
*/
export const resolveEndpoints = (graphqlUrl: string) : {httpUrl: string, wsUrl: string} => {
  
  let httpUrl
  let wsUrl

  if (Config.useLocalDgraph) {
    httpUrl = 'http://localhost:8080/graphql'
    wsUrl = 'ws://localhost:8080/graphql'
  } else {
    httpUrl = `https://${graphqlUrl}`
    wsUrl = `wss://${graphqlUrl}`
  }

  return {
    httpUrl,
    wsUrl,
  }
}