import { ExtrudeGeometryOptions } from "three";


const bevelSize = 0.04

export const extrudeSettingsInternalSiteBoundaryWall: ExtrudeGeometryOptions = {
	depth: 1.5,
	bevelEnabled: false,
	steps: 1,
	bevelOffset: -bevelSize, 
	bevelSegments: 1, 
	bevelSize: bevelSize, 
	bevelThickness: bevelSize
};

export const extrudeSettingsArea: ExtrudeGeometryOptions = {
	depth: 1,
	steps: 1,
	bevelEnabled: false,
	bevelOffset: -bevelSize, 
	bevelSegments: 1, 
	bevelSize: bevelSize, 
	bevelThickness: bevelSize
};

export const extrudeSettingsWall: ExtrudeGeometryOptions = {
	depth: 3,
	bevelEnabled: false,
	steps: 1,
	bevelOffset: -bevelSize, 
	bevelSegments: 1, 
	bevelSize: bevelSize, 
	bevelThickness: bevelSize
};