import React, {
  forwardRef,
} from 'react';
import {
  makeStyles,
  shorthands,
  useId,
  Input,
  Label,
  MessageBar,
} from "@fluentui/react-components";
import type { InputProps } from "@fluentui/react-components";

interface Props {
  className?: string
  id: string
  label?: string
  errorMessage?: string
  props: InputProps
}

const useStyles = makeStyles({
  root: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
  },
});

const TLInput: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      id,
      label,
      errorMessage,
      props
    },
    ref
  ) => {

  const inputId = useId(id);
  const styles = useStyles();

  return (
    <div className={className}>
      <div className={styles.root}>
        <Label htmlFor={inputId} size={props.size} disabled={props.disabled}>
          {label}
        </Label>
        <Input
          ref={ref}
          id={inputId}
          {...props}
        />
        { errorMessage ? <MessageBar id={`${id}-error`} intent="error">{errorMessage}</MessageBar> : null }
      </div>
    </div>
  );
});

export default TLInput