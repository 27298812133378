import React, { useEffect, useRef, useState } from "react"

// Types
import { FilterPath } from "../../@types/FilterPath"

// Context
import { useFilterContext } from "../../common/contexts/FilterContext"

// Components
import TLFilterPill from "./TLFilterPill"
import TLFilterClearPill from "./TLFilterClearPill"
import { ChevronLeft16Filled, ChevronRight16Filled } from "@fluentui/react-icons"
import './TLFilter.css';
import { ReactComponent as DotsIcon } from '../../icons/dots.svg';


interface Props {
    className?: string
}

const TLFilter: React.FC<Props> = ({ className }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const { filter, removeFilter, clearFilter } = useFilterContext();
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
  
    const checkOverflowAndScrollPosition = () => {
        const container = containerRef.current;
        if (container) {
            const scrollWidth = container.scrollWidth;
            const clientWidth = container.clientWidth;
            const scrollLeft = container.scrollLeft;
    
            setIsOverflowing(scrollWidth > clientWidth);
            setShowLeftArrow(scrollLeft > 0);
    
            // Add a buffer to handle floating-point precision
            const buffer = 5;
            setShowRightArrow(scrollLeft + clientWidth < scrollWidth - buffer);
        }
    };
    
    const scrollContainer = (direction: 'left' | 'right') => {
        const container = containerRef.current;
        if (container) {
            const scrollAmount = 300; 
            const firstPill = container.querySelector('.filter-pill'); // First pill element
            const firstPillWidth = firstPill ? (firstPill as HTMLElement).offsetWidth : 0; // Get its width
    
            let newScrollPosition;
    
            if (direction === 'left') {
                // Ensure we scroll back to the start and fully reveal the first pill
                if (container.scrollLeft <= firstPillWidth) {
                    newScrollPosition = 0; // Snap to the beginning of the container
                } else {
                    newScrollPosition = container.scrollLeft - scrollAmount;
                }
    
                container.scrollTo({
                    left: newScrollPosition,
                    behavior: 'smooth',
                });
            } else {
                // Scroll right, ensuring we don't exceed the max scrollable width
                newScrollPosition = Math.min(
                    container.scrollWidth - container.clientWidth,
                    container.scrollLeft + scrollAmount
                );
    
                
                container.scrollTo({
                    left: newScrollPosition,
                    behavior: 'smooth',
                });
            }
    
            // Recheck scroll position after the scroll is done
            setTimeout(() => {
                checkOverflowAndScrollPosition();
            }, 400);
        }
    };
        

    useEffect(() => {
        checkOverflowAndScrollPosition();
        window.addEventListener('resize', checkOverflowAndScrollPosition);

        return () => {
            window.removeEventListener('resize', checkOverflowAndScrollPosition);
        };
    }, []);

    useEffect(() => {
        checkOverflowAndScrollPosition();
    }, [filter]);

    return (
        <div className={`${className} filter`}>
            {showLeftArrow && (
                <div className="flex">
                    <button className="arrow left" 
                        onClick={() => scrollContainer('left')}>
                        <ChevronLeft16Filled />
                    </button>
                    <DotsIcon className="dots left" />
                </div>
            )}
            <div className="filter-pills" ref={containerRef}>
                {filter.map((f: FilterPath, i) => {
                    return (
                        <React.Fragment key={i}>
                            {f.display &&
                                <div  className="filter-pill-wrapper">
                                    <TLFilterPill onClick={() => { removeFilter(i) }} label={f.label} />
                                </div>
                            }
                        </React.Fragment>
                    )
                })}
            </div>
            {showRightArrow && (
                <div className="flex">
                    <DotsIcon className="dots right" />
                    <button className="arrow right"
                        onClick={() => scrollContainer('right')}>
                            {<ChevronRight16Filled />}
                    </button>
                </div>
            )}
            {filter.length > 0 && <TLFilterClearPill onClick={() => {clearFilter()}} label="Clear all" />}
        </div>
    )
}

export default TLFilter