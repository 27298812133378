import { MessageBar } from "@fluentui/react-components"
import React from "react"
interface Props {
    className?: string
}

const ErrorPage: React.FC<Props> = ({ className }) => {
    return (
        <>
        <div >
            <MessageBar>Something went wrong.</MessageBar>
        </div>
    </>
    )
}

export default ErrorPage