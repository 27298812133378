import React from "react"
import {
    Button
} from "@fluentui/react-components";
import type { ButtonProps } from "@fluentui/react-components";

interface Props {
    className?: string
    children?: string
    props?: ButtonProps
    onClick?: () => void
}

const TLButton: React.FC<Props> = ({className, children, props, onClick}) => {
    return (
        <div className={className}>
            <Button onClick={onClick}>{children}</Button>
        </div>
    )
}

export default TLButton