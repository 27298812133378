import { gql, } from '@apollo/client';

export const QUERY_METRIC_VALUE = gql`
    subscription queryMetricValue {
        queryMetricValue {
            entityIDmetricID
            entityID
            metricID
            sensorID
            timestamp
            value
        }
    }
`