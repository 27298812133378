/*
 * stringToTwinEntityType
 * Handling inconsistencies in back-end data
 *
*/
import { TwinEntityType } from "../../@types/TwinEntityType";

export const stringToTwinEntityType = (value: string): TwinEntityType => {
    let type = TwinEntityType.UNKNOWN
    switch (value) {
        case `area`:
            type = TwinEntityType.AREA
            break;
        case `Area`:
            type = TwinEntityType.AREA
            break;
        case `AREA`:
            type = TwinEntityType.AREA
            break;
        case `asset`:
            type = TwinEntityType.ASSET
            break;
        case `Asset`:
            type = TwinEntityType.ASSET
            break;
        case `ASSET`:
            type = TwinEntityType.ASSET
            break;
        case `category`:
            type = TwinEntityType.CATEGORY
            break;
        case `Category`:
            type = TwinEntityType.CATEGORY
            break;
        case `CATEGORY`:
            type = TwinEntityType.CATEGORY
            break;
        case `floor`:
            type = TwinEntityType.FLOOR
            break;
        case `Floor`:
            type = TwinEntityType.FLOOR
            break;
        case `FLOOR`:
            type = TwinEntityType.FLOOR
            break;
        case `nfarea`:
            type = TwinEntityType.NFAREA
            break;
        case `Nfarea`:
            type = TwinEntityType.NFAREA
            break;
        case `NFAREA`:
            type = TwinEntityType.NFAREA
            break;
        case `site`:
            type = TwinEntityType.SITE
            break;
        case `Site`:
            type = TwinEntityType.SITE
            break;
        case `SITE`:
            type = TwinEntityType.SITE
            break;
        case `structure`:
            type = TwinEntityType.STRUCTURE
            break;
        case `Structure`:
            type = TwinEntityType.STRUCTURE
            break;
        case `STRUCTURE`:
            type = TwinEntityType.STRUCTURE
            break;
        case `wall`:
            type = TwinEntityType.WALL
            break;
        case `WALL`:
            type = TwinEntityType.WALL
            break;
        case `Wall`:
            type = TwinEntityType.WALL
            break;
        case `zone`:
            type = TwinEntityType.ZONE
            break;
        case `Zone`:
            type = TwinEntityType.ZONE
            break;
        case `ZONE`:
            type = TwinEntityType.ZONE
            break;
        case `event`:
            type = TwinEntityType.EVENT
            break;
        case `Event`:
            type = TwinEntityType.EVENT
            break;
        case `EVENT`:
            type = TwinEntityType.EVENT
            break;
        case `station`:
            type = TwinEntityType.STATION
            break;
        case `Station`:
            type = TwinEntityType.STATION
            break;
        case `STATION`:
            type = TwinEntityType.STATION
            break;
        case `operator`:
            type = TwinEntityType.OPERATOR
            break;
        case `Operator`:
            type = TwinEntityType.OPERATOR
            break;
        case `OPERATOR`:
            type = TwinEntityType.OPERATOR
            break;
    }
    return type
}