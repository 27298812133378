/**
	* NonFunctionalAreaVolume Component
    * 
	* Intended for areas which are not monitored properly
*/

import { useMemo } from "react";
import { expandOutline } from "./utils/outlineUtils";
import { DoubleSide, ExtrudeGeometry, MeshStandardMaterial, Shape, Vector2 } from "three";
import { extrudeSettingsWall } from "./utils/extrusionConfig";
import { TwinEntity } from "../../generated/devGraphql";
import { coordLocalOutlineToVector2 } from "./utils/coorUtils";
import { defaultColours } from "./theme";


const wallMaterial = new MeshStandardMaterial({
	color: defaultColours.staticGray,
	transparent: true,
	opacity: 0.5,
	roughness: 0.45,
	metalness: 0.3,
	side: DoubleSide,
	normalScale: new Vector2(0.11, 0.11),
	forceSinglePass: true
})
             
      


interface Props { 
	entity: TwinEntity
	depth: number
}

export const Wall = ({ entity, depth }: Props) => {

	const areaGeometry = useMemo(() => {

		const shapes: Shape[] = []

		entity.boundaries?.polygons.forEach(polygon => {
			
			const worldSpaceOutline = coordLocalOutlineToVector2(polygon.coordinates[0].points)
			const areaPoints = expandOutline(
				worldSpaceOutline,
				-0.001
			);
	
			const areaShape = new Shape(areaPoints);

			shapes.push(areaShape)

		})

		const areaGeo = new ExtrudeGeometry(
			shapes,
			extrudeSettingsWall
		);

		areaGeo.rotateX(-Math.PI / 2);

		return areaGeo;

	}, [entity]);

	return  <mesh
				geometry={areaGeometry}
				material={wallMaterial}
				position={[
					0,
					depth / 100 - 0.01,
					0,
				]}
				castShadow
				receiveShadow
			/>
	
}