import { useEffect, useState } from "react"

// Types
import { Data } from "../../@types/Data/Data"
import { Entity } from "../../@types/Entity"
import { Label } from "../../@types/Settings/Label"

// Context
import { useDataContext } from "../../common/contexts/DataContext"
import { useFilterContext } from "../../common/contexts/FilterContext"
import { useSettingsContext } from "../../common/contexts/SettingsContext"

// Utils
import { getLabelValue } from "../../common/utils/getLabelValue"


interface Props {
    entity: Entity
    label: Label
}

const LabelValue = ({entity, label}: Props) => {
    
    const { settings } = useSettingsContext()
    const { live, startDateTime, finishDateTime } = useFilterContext()
    const { data } = useDataContext()
    const [loading, setLoading] = useState(true)
    const [value, setValue] = useState(0)

    useEffect(() => {
        const fetchLabelValue = async (entity: Entity, label: Label, data: Data, startDateTime: string | null, finishDateTime: string | null) => {

            let labelValue

            if (settings) {
                labelValue = await getLabelValue(settings.organisation, entity, label, data, startDateTime ? startDateTime.split('.')[0] : null, finishDateTime ? finishDateTime.split('.')[0] : null)
            } else {
                throw new Error("You must set an organisation with the Twin Setup/Preferences");
            }

            if (labelValue) {
                setValue(labelValue)
            }
            setLoading(false)
        }

        fetchLabelValue(entity, label, data, startDateTime, finishDateTime)
        
    }, [
        entity,
        label,
        live,
        data,
        startDateTime,
        finishDateTime,
        settings
    ])
    
    return (
        <>
        {loading ?
            <>
                Loading...
            </>
        :
            <>
                {value}
            </>
        }
        </>
    )
}

export default LabelValue