import { CSSProperties } from "react"

// Types
import { Entity } from "../../@types/Entity"
import { Label } from "../../@types/Settings/Label"

// Components
import LabelValue from "./LabelValue"

interface Props {
    entity: Entity
    labels: Label[]
    cssProperties?: CSSProperties
}

const Labels = ({ entity, labels, cssProperties } : Props) => {
    return (
        <ul className="labels" style={cssProperties}>
            {labels.map((label, i) => {
                return (
                    <li key={i}><span>{label.name}</span> <LabelValue entity={entity} label={label} /></li>
                )
            })}
        </ul>
    )
}

export default Labels